import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getOrders = createAsyncThunk('menu/getOrders', async ({ hotMeal = null, selectedFoodMeal = null }) => {
    let totalQuantity = 0;
    let totalPrice = 0;

    // Calculate total quantity and total price from hotMeal
    if (hotMeal && hotMeal.length > 0) {
        hotMeal.forEach(item => {
            totalQuantity += item.quantity;
            totalPrice += parseFloat(item.Menu.price) * item.quantity;
        });
    }

    // Calculate total quantity and total price from selectedFoodMeal
    if (selectedFoodMeal && selectedFoodMeal.length > 0) {
        selectedFoodMeal.forEach(item => {
            totalQuantity += item.quantity;
            totalPrice += parseFloat(item.price) * item.quantity;
        });
    }
    // Return an object containing total quantity and total price
    return { totalQuantity, totalPrice };
});
const orderSlice = createSlice({
    name: 'order',
    initialState: {
        loading: false,
        order: [],
        error: ''
    },

    // reducer will call here
    extraReducers: builder => {
        builder.addCase(getOrders.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getOrders.fulfilled, (state, action) => {
            state.loading = false;
            state.order = action.payload;
            state.error = '';
        });
        builder.addCase(getOrders.rejected, (state, action) => {
            state.loading = false;
            state.menu = [];
            state.error = action.error.message;
        });
    }
});
export default orderSlice.reducer;