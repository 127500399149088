import React, { useEffect } from 'react'
import { fetchCategories } from '../../app/categories/categoriesSlice';
import { useDispatch, useSelector } from 'react-redux';
import DefaultPagination from "../../config/Pagination";
import { image_url } from '../../Utils/baseUrl';
import { CAT_IMAGE_PATH } from '../../Utils/baseUrl';
import { NavLink } from 'react-router-dom';

function Categories() {

    const dispatch = useDispatch();
    let categoryData = {
        search: '',
        pageNumber: DefaultPagination.PAGE_NUMBER,
        // pageSize: DefaultPagination.PAGE_SIZE,
        pageSize: 50,
    };

    useEffect(() => {
        dispatch(fetchCategories(categoryData));
        window.scrollTo(0, 0)
        // eslint-disable-next-line
    }, [categoryData.pageNumber, categoryData.pageSize]);

    const category = useSelector((state) => state.categories);

    return (
        <React.Fragment>
            <section className="sec-pd rel-z-1">
                <div className="top-bottom-shaps">
                    <span className="shap shap-1" />
                    <span className="shap shap-2" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div
                                className="title-1 title-center wow fadeInUp"
                                data-wow-delay="0.2s"
                            >
                                <h1>Our Categories</h1>
                                <div className="btm-line-1 text-center justify-content-center">
                                    <span />
                                    <span />
                                </div>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                                    suada nisi. Donec <br />
                                    suscipit varius risus, non luctus nisl euismod sit amet. Quisque
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row wow fadeInUp">
                        {category.categories?.data?.categories.map((item, index) => (
                            <div className="col-lg-4 col-md-6 col-sm-6 d-flx" key={index}>
                                <NavLink to={`/menubyCategory/${item.idCategory}`}>
                                    <div className="menu-card">
                                        <div className="menu-card-img">
                                            <img src={`${image_url}${CAT_IMAGE_PATH}` + item.imageUrl} alt='not found' />
                                        </div>
                                        <div className="menu-type wow fadeInUp" data-wow-delay="0.2s">
                                            <p>{item.title}</p>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Categories;