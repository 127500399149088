import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { useState } from 'react'
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import * as yup from "yup";
import commonFunction from '../../Utils/commonFunction';
import Cookies from 'js-cookie';
import { userLogout } from '../../app/login/login.slice';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { config } from '../../Utils/axiosconfig';

function ChangePassword({ isPasswordModalOpen, closePasswordModal }) {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const schema = yup.object().shape({
        oldPassword: yup.string().required("Old Password is Required!"),
        newPassword: yup.string().required("New Password is Required!"),
    });

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: ''
        },
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                const response = await commonFunction.changePassword(values);
                if (response.status === 200 && response?.data?.status === true) {
                    toast.success("Password Changes Successfully!! Please login again");
                    closePasswordModal();
                    // Reset the form after successful submission
                    formik.resetForm();
                    Cookies.remove('token');
                    await dispatch(userLogout(config));
                    await navigate('/login');
                } else {
                    toast.error(response?.data?.message);
                }
            } catch (error) {
                // Handle error if necessary
                toast.error("Error submitting the form.");
            }
        },
    });
    return (
        <Dialog open={isPasswordModalOpen} onClose={closePasswordModal}>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>Change your account password</DialogTitle>
                <DialogContent>
                    Please enter old and new passowrd.
                    <br />
                    <br />
                    <div className="form-group">
                        <label htmlFor="oldPassword">Old Password</label>
                        <input type="text" className="form-control"
                            id="oldPassword"
                            aria-describedby="emailHelp"
                            placeholder="Enter Old Password"
                            value={formik.values.oldPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="on"
                        />
                        {formik.touched.oldPassword && formik.errors.oldPassword && (
                            <div className="error m-1">{formik.errors.oldPassword}</div>
                        )}
                    </div>

                    <div className="form-group">
                        <label htmlFor="newPassword">New Password</label>
                        <div className="d-flex align-items-center ">
                            <input type={showPassword ? 'text' : 'password'}
                                className="form-control" id="newPassword"
                                aria-describedby="emailHelp"
                                placeholder="Enter New Password"
                                value={formik.values.newPassword}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="on"
                            />
                            <i className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`} aria-hidden="true" onClick={togglePasswordVisibility} style={{ marginLeft: '10px' }}></i>
                        </div>
                        {formik.touched.newPassword && formik.errors.newPassword && (
                            <div className="error m-1">{formik.errors.newPassword}</div>
                        )}
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={closePasswordModal} color="primary">
                        Cancel
                    </Button>
                    <Button type="submit" color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </form>
        </Dialog>

    )
}

export default ChangePassword