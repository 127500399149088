import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useFormik } from 'formik';
import { Input, Space } from 'antd';
import * as yup from 'yup';
import commonFunction from '../../Utils/commonFunction';
import { useNavigate } from 'react-router';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const WalletModal = ({ handleOpen, handleClose, remainingAmount }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object({
    amount: yup.string().required('Amount is required'),
  });

  const formik = useFormik({
    initialValues: {
      amount: '',
    },

    onSubmit: async (values) => {
      setLoading(true)
      try {
        let orderId = '';
        const response = await commonFunction.addTransaction(values)
        if (response.status) {
          orderId = response?.data?.id
          const paymentObj = {
            currency: 'dkk',
            order_id: 'transaction-' + response.data.id
          }
          await commonFunction.createPayment(paymentObj).then((res) => {
            if (res.data.id) {
              navigate('/make-payment', {
                state: {
                  amount: (values.amount * 100),
                  paymentRes: res,
                  orderId: orderId
                }
              });
              setLoading(false);
            }
          });
          formik.resetForm();

        } else {
          console.error(response.message);
        }
      }
      catch (error) {
        alert.error('Please wait!! Something went wrong !!');
      }

    },
    validationSchema
  });

  return (
    <div>
      <Modal
        open={handleOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Insufficient Wallet Amount
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Please recharge your wallet to place this order.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} component="h2">
            You need {remainingAmount} kr. more to place this order
          </Typography>
          <br />
          <div className="col-md-12">
            <form onSubmit={formik.handleSubmit}>
              <Space.Compact>
                <Input
                  placeholder='Please Enter amount'
                  type='number'
                  name='amount'
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                />
                <button type='submit' style={{ width: '30%' }} className='btn btn-primary'>Add wallet</button>
              </Space.Compact>
              {formik.touched.amount && formik.errors.amount ? (
                <div className="error-message">{formik.errors.amount}</div>
              ) : null}
            </form>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default WalletModal
