import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import commonFunction from '../../Utils/commonFunction';
import { useDispatch } from 'react-redux';
import { getWalletAmount } from '../../app/wallet/wallet.slice';


const Cancelorder = ({ isCancelOrderOpen, iscloseCancelOrder, id, orderData, setOrders, orderItem }) => {

    const dispatch = useDispatch();
    const handleSubmit = async () => {
        await commonFunction.cancelOrder(id).then(res => {
            if (res.data.status === true) {
                toast.success(res.data.message);
                const getOrders = commonFunction.getOrders(orderData);
                getOrders.then((res) => {
                    setOrders(res.data);
                    iscloseCancelOrder();
                })

            } else {
                toast.info(res.data.message);
                iscloseCancelOrder();
            }
            dispatch(getWalletAmount());
        }).catch(err => {
            toast.error('something went wrong');
        })
    };

    return (
        <Dialog open={isCancelOrderOpen} onClose={iscloseCancelOrder}>
            <DialogTitle>Cancel your order</DialogTitle>
            <DialogContent>
                Are you sure for canceling order this {orderItem?.OrdersItems[0]?.itemName} at deliveryDate {orderItem?.deliveryDate} for child {orderItem?.Child.name} ?
            </DialogContent>
            <DialogActions>
                <Button onClick={iscloseCancelOrder} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Cancelorder;
