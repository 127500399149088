// export const base_url = "http://18.134.95.148:5000/web/api/v1/";
// export const image_url = "http://18.134.95.148:5000/"
// export const public_url = "http://18.134.95.148:5000/api/";
// export const admin_url = "http://18.134.95.148:5000/admin/api/v1/";

// Live urls
export const base_url = "https://api.onlineskolemad.dk/web/api/v1/";
export const image_url = "https://api.onlineskolemad.dk/"
export const public_url = "https://api.onlineskolemad.dk/api/";
export const admin_url = "https://api.onlineskolemad.dk/admin/api/v1/";

export const CAT_IMAGE_PATH = "/uploads/category-img/";
export const MENU_IMAGE_PATH = "/uploads/menu-img/";
export const PROFILE_IMAGE_PATH = "public/uploads/profile-img/";
export const site_url = window.location.origin;