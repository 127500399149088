import React from 'react'
import { useEffect } from 'react'
import commonFunction from '../../Utils/commonFunction'
import { useState } from 'react'

function ProfileHome({ isOrder, addChild }) {

    const [pageData, setPageData] = useState('')
    const [pageHeading, setPageHeading] = useState('')

    useEffect(() => {
        let id = 2;
        commonFunction.getPageContent(id).then((res) => {
            setPageData(res?.data?.page?.description)
            setPageHeading(res?.data?.page?.heading);
        }).catch((err) => {
            console.log(err, 'error');
        })
    }, [])

    return (
        <div
            className={isOrder || addChild ? "tab-pane fade" : "tab-pane fade show active"}
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
        >
            <div className="tab_innerBody">
                <div className="tab_innerBodyTitle">
                    <h4>{pageHeading}</h4>
                </div>
                <div className="tab_subItems">
                    <div className="tab_tableItem">
                        <div dangerouslySetInnerHTML={{ __html: pageData }} />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ProfileHome