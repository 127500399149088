import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { image_url, MENU_IMAGE_PATH } from "../../Utils/baseUrl";
import commonFunction from "../../Utils/commonFunction";

function DishOfDay() {
  const [dishOfDay, setDishOfDay] = useState({});

  useEffect(() => {
    // Get today's day and convert it into the required format
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const today = new Date().getDay();
    const todayFormatted = days[today];

    const fetchData = async () => {
      try {
        const response = await commonFunction.fetchDataById(
          "getDishOfTheDay",
          todayFormatted
        );
        setDishOfDay(response.data);
      } catch (error) {
        console.error("Error fetching dish of the day:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <section className="sec-pd bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="title-1 title-center wow fadeInUp"
                data-wow-delay="0.2s"
              >
                <h1>Dagens varme ret</h1>
                <div className="btm-line-1 text-center justify-content-center">
                  <span />
                  <span />
                </div>
                <p>
                  Her er Dagens varme ret, den ændre sig fra dag til dag De
                  kommer med varme bokse på skolerne, så de stadig varme
                </p>
              </div>
            </div>
          </div>
          <div className="row wow fadeInUp">
            {dishOfDay?.HotMeallist?.map((item, index) => (
              <div key={index} className="col-lg-4 col-md-6 col-sm-6 d-flex">
                <div className="menu-card">
                  <div className="menu-card-img">
                    <img
                      src={
                        item?.Menu?.MenuImages[0]?.image
                          ? `${image_url}${MENU_IMAGE_PATH}` +
                            item?.Menu?.MenuImages[0]?.image
                          : ""
                      }
                      alt="not found"
                    />
                  </div>
                  <div className="menu-desc">
                    <div className="menu-desc-head">
                      <h5>{item?.Menu?.title}</h5>
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item.Menu.description.replace(/<[^>]+>/g, ""),
                      }}
                    ></p>
                    <NavLink
                      className="btn btn-1"
                      to={`/menuDetails/${item?.menuId}`}
                    >
                      View more
                    </NavLink>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default DishOfDay;
