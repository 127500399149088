import React, { useEffect, useState } from 'react'
import './style.css';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useLocation, useNavigate } from 'react-router-dom';
import commonFunction from '../../Utils/commonFunction';
import { toast } from 'react-toastify';
import { getWalletAmount } from '../../app/wallet/wallet.slice';
import { useDispatch } from 'react-redux';


function Payment() {

  const location = useLocation();
  const [createPay, setCreatePay] = useState('');
  const [state, setState] = useState({ focus: '', });
  const [paymentLoading, setPaymentLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // Access the location state variable here
    setCreatePay(location?.state?.paymentRes);

    // Your API request code here
  }, [location.state]); // Trigger effect when location state changes

  const handleInputFocus = (evt) => {
    setState((prev) => ({ ...prev, focus: evt.target.name }));
  }

  const validationSchema = Yup.object({
    number: Yup.string()
      .required('Card number is required')
      .max(16, 'Card number must be at most 16 characters'),
    expiry: Yup.string()
      .required('Expiration date is required')
      .max(4, 'Expiry must be at most 4 characters'),
    cvc: Yup.string()
      .required('CVV date is required')
      .max(3, 'CVV must be at most 3 characters'),
    name: Yup.string().required('Cardholder name is required'),
  });
  const formik = useFormik({
    initialValues: {
      number: '',
      expiry: '',
      cvc: '',
      name: '',
    },
    onSubmit: async (values) => {
      setPaymentLoading(true);
      await commonFunction.authorizePayment(createPay, values, location.state.amount).then((res) => {
        if (res?.status === 400) {
          toast.error(res?.data?.data?.message);
        }
        if (res?.status === 200) {
          setTimeout(() => {

            commonFunction.capturePayment(res, location.state.amount).then((res) => {
              if (res?.status === 200 && res?.data?.data?.accepted === true) {
                toast.success('Payment Successful');

                commonFunction.storeTransaction(res, location.state.orderId).then((res) => {
                  setPaymentLoading(false);
                  dispatch(getWalletAmount());
                  navigate('/myprofile');
                })

              } else {
                toast.error('Network Error!! Transaction cancelled Please try again');
                navigate('/myprofile');
              }
            });
          }, 5000);
        }

        if (res.code === "ERR_NETWORK") {
          toast.error('Network Error!! Transaction cancelled');
          navigate('/myprofile');
        }
      }).catch((err) => {
        console.log(err);
        toast.error('Some Thing Went Wrong!! Transaction cancelled');
        navigate('/myprofile');

      });
    },
    validationSchema
  });

  return (
    <div>
      <div className="payment-container">
        <h2>Quick Payment Form</h2>
        <Cards
          number={formik.values.number}
          expiry={formik.values.expiry}
          cvc={formik.values.cvc}
          name={formik.values.name}
          focused={state.focus}
        />
        <form onSubmit={formik.handleSubmit} className='mt-3'>
          <div className='row'>

            {/* Card Number */}
            <div className="form-group col-md-6">
              <label htmlFor="cardNumber">Card Number</label>
              <input type="number"
                name="number"
                placeholder="Card Number"
                value={formik.values.number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onFocus={handleInputFocus}
              />
              {formik.touched.number && formik.errors.number ? (
                <div className="error-message">{formik.errors.number}</div>
              ) : null}
            </div>
            {/* Card Expire */}
            <div className="form-group col-md-6">
              <label htmlFor="expiryDate">Expiration Date (YYMM format)</label>
              <input type="number"
                name="expiry"
                placeholder="YYMM"
                value={formik.values.expiry}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onFocus={handleInputFocus}
              />
              {formik.touched.expiry && formik.errors.expiry ? (
                <div className="error-message">{formik.errors.expiry}</div>
              ) : null}
            </div>
            {/* Card CVV */}
            <div className="form-group col-md-6">
              <label htmlFor="cvv">CVV</label>
              <input
                type="number"
                name="cvc"
                placeholder="CVC"
                value={formik.values.cvc}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onFocus={handleInputFocus}
              />
              {formik.touched.cvc && formik.errors.cvc ? (
                <div className="error-message">{formik.errors.cvc}</div>
              ) : null}
            </div>
            {/* Card Holder Name */}
            <div className="form-group col-md-6">
              <label htmlFor="name">Card Holder Name</label>
              <input
                type="text"
                name="name"
                placeholder="CardHolder Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onFocus={handleInputFocus}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="error-message">{formik.errors.name}</div>
              ) : null}
            </div>
          </div>
          {!paymentLoading && (
            <button type="submit">Pay Now</button>
          )}
          {paymentLoading && (
            <>
              <div className="error-message">Please wait.. Do not refresh page, Payment is in progress..</div>
              <button className="btn btn-primary" type="button" disabled>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                Loading...
              </button>
            </>
          )}
        </form>
      </div>
    </div>
  );
}

export default Payment  
