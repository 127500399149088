import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { base_url } from '../../Utils/baseUrl';
import { config } from '../../Utils/axiosconfig';
import AddChild from '../ProfileModals/addchild';
import EditChild from '../ProfileModals/editchild';
import DefaultPagination from "../../config/Pagination";
import { fetchChild } from '../../app/child/childSlice';
import commonFunction from '../../Utils/commonFunction';
import Pagination from '.././Common/Pagination';

function UserChild({ addChild }) {
    const [school, setSchool] = useState();
    const [classes, setClasses] = useState();
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [childId, setChildId] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const dispatch = useDispatch();

    // Use state to manage userData
    const [userData, setUserData] = useState({
        search: '',
        pageSize: DefaultPagination.PAGE_SIZE,
        pageNumber: DefaultPagination.PAGE_NUMBER,
    });

    const paginationWithPage = (pageNumber) => {
        // showChildData.pageNumber = pageNumber;
        setUserData((prevUserData) => ({ ...prevUserData, pageNumber: pageNumber }));
        dispatch(fetchChild(userData));
    };

    const child = useSelector((state) => state.child);
    const closeaddModal = () => setIsAddModalOpen(false);
    const closeModal = () => setIsModalOpen(false);

    const openAddModal = () => {
        setIsAddModalOpen(true);
    }

    const openModal = (id) => {
        setChildId(id);
        setIsModalOpen(true);
    }

    useEffect(() => {
        if (!school) {
            const schools = commonFunction.getSchools();
            schools.then((res) => {
                setSchool(res.data);
            })
        }
        if (!classes) {
            const schoolClass = commonFunction.fetchData('getSchoolClass');
            schoolClass.then((res) => {
                setClasses(res);
            })
        }
        dispatch(fetchChild(userData));

        window.scrollTo(0, 0)
        // eslint-disable-next-line
    }, [userData, school, classes]);

    const handleDelete = async (childId) => {
        const childToDelete = child?.getchild?.data?.child.find(child => child.id === childId);
        if (!childToDelete) {
            toast.error('Child not found');
            return;
        }
        const shouldDelete = window.confirm(`Are you sure you want to delete ${childToDelete.name}?`);
        if (shouldDelete) {
            try {
                await axios.get(`${base_url}deleteChild/${childId}`, config).then(res => {
                    toast.success(res.data.message);
                    dispatch(fetchChild(userData));
                }).catch(err => {
                    console.log(err, 'err');
                })
            } catch (error) {
                console.error('An error occurred while deleting the child:', error);
                toast.error('Please wait!! Something went wrong !!');
            }
        };
    }

    return (
        <div
            className={addChild ? "tab-pane fade show active" : "tab-pane fade"}
            id="child"
            role="tabpanel"
            aria-labelledby="child-tab"
        >
            <div className="tab_innerBody">
                <div className="tab_innerBodyTitle">
                    <h4>My Child</h4>
                </div>
                <div className="tab_subItems">
                    <div className="tab_tableItem">
                        <div className="text-right mb-3">
                            <button className='btn btn-secondary' onClick={() => { openAddModal() }} >Add child</button>
                        </div>
                        <table className="table table-striped text-center">
                            <thead>
                                <tr>
                                    <th scope="col">Full Name</th>
                                    <th scope="col">School</th>
                                    <th scope="col">Class</th>
                                    <th scope="col" className="text-right">
                                        Action
                                    </th>
                                </tr>
                                {(child?.getchild?.data?.child != undefined && child?.getchild?.data?.child.length > 0) ?
                                    child?.getchild?.data?.child?.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item?.name}</td>
                                            <td>{item?.School?.schoolName}</td>
                                            <td>{item?.SchoolClass?.className}</td>
                                            <td>
                                                {/* <Link to={''} >Edit</Link> */}
                                                <i className="fa fa-pen" onClick={() => { openModal(item.id) }} style={{ cursor: 'pointer' }}></i>
                                                &nbsp;
                                                |
                                                &nbsp;
                                                <i className="fa fa-trash" onClick={() => { handleDelete(item.id) }} style={{ cursor: 'pointer' }}></i>
                                            </td>
                                        </tr>
                                    ))
                                    :
                                    <tr>
                                        <td colSpan={9}>No order found !!</td>
                                    </tr>
                                }
                            </thead>
                        </table>
                    </div>
                    {/* Pagination component */}
                    <Pagination
                        totalPage={child?.getchild?.data?.pagination?.totalPages}
                        currentPage={child?.getchild?.data?.pagination?.currentPage}
                        fullPagination={paginationWithPage}
                    />
                </div>
            </div>
            <AddChild
                isAddOpen={isAddModalOpen}
                closeaddModal={closeaddModal}
                school={school}
                classes={classes}
            />
            <EditChild
                isOpen={isModalOpen}
                closeModal={closeModal}
                childId={childId}
                school={school}
                classes={classes}
                userData={userData}
            />
        </div>
    )
}

export default UserChild