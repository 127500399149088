import React, { useState } from 'react'
import { Navbar } from '../Navbar'
import { Link } from 'react-router-dom';
import logo from '../../../logo.svg'
import { useSelector } from 'react-redux';

function Header() {
    const [navActive, setNavActive] = useState(false);
    const contactDetails = useSelector((state) => state.contact);
    const handleToggle = () => {
        navActive === false ? setNavActive(true) : setNavActive(false);

        // Add or remove class from body tag based on navActive state
        if (!navActive) {
            document.body.classList.add('menu-show');
        } else {
            document.body.classList.remove('menu-show');
        }
    }
    return (
        <>
            {/* Header */}
            <div className="header-top-strip wow fadeInDown" data-wow-delay="0.0s">
                <div className="container">
                    <ul className="icon-list">
                        <li className="wow fadeInDown" data-wow-delay="0.3s"><span><i className="fas fa-envelope" />{contactDetails.contact?.data?.email}</span></li>
                        <li className="wow fadeInDown" data-wow-delay="0.1s"><a href={`tel:${contactDetails.contact?.data?.phone}`}> <i className="fas fa-phone-alt" />{contactDetails.contact?.data?.phone}</a></li>
                    </ul>
                </div>
            </div>
            <header className="header wow fadeIn" data-wow-delay="0.0s">
                <div className="container">
                    <div className="top-logo-nav d-flex-wrap justify-content-between align-items-center" style={{ position: 'relative', right: '60px' }}>
                        <Link to={'/'} className='navbar-brand wow fadeInLeft' data-wow-delay="0.1s" >
                            <img src={logo} alt='not found' style={{ width: '18rem', marginTop: '9px' }} />
                        </Link>
                        <div className="tln-right">
                            <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
                                onClick={handleToggle}>
                                <span />
                                <span />
                                <span />
                            </button>
                            <Navbar
                                setNavActive={setNavActive}
                                navActive={navActive}
                            />
                        </div>
                    </div>
                </div>
            </header>
            {/* Header */}
        </>
    )
}

export default Header