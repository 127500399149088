import { configureStore } from '@reduxjs/toolkit';
import contactSlice from './contact/contactSlice';
import menuSlice from './menu/menuSlice';
import categoriesSlice from './categories/categoriesSlice';
import menudetailSlice from './menudetails/menudetailSlice';
import recommendeddishSlice from './recommendeddish/recommendeddishSlice';
import getMenubyCategory from './menubycategory/menubycategorySlice';
import loginSlice from './login/login.slice';
import childSlice from './child/childSlice';
import walletSlice from './wallet/wallet.slice';
import TransactionSlice from './transaction/Transaction.slice';
import orderSlice from './orders/order.slice';


export const store = configureStore({
    reducer: {
        contact: contactSlice,
        menu: menuSlice,
        categories: categoriesSlice,
        menuDetails: menudetailSlice,
        recommendedDishes: recommendeddishSlice,
        menuByCategory: getMenubyCategory,
        user: loginSlice,
        child: childSlice,
        wallet: walletSlice,
        Transaction: TransactionSlice,
        order: orderSlice
    }
});

export default store;