import axios from 'axios';
import { base_url } from "../../Utils/baseUrl";

const getRecommendedDish = async (recommendedDishData) => {
    const requestConfig = {}
    requestConfig.params = {
        search: recommendedDishData.search,
        pageSize: recommendedDishData.pageSize,
        pageNumber: recommendedDishData.pageNumber,
    };

    const response = await axios.get(`${base_url}menus`, requestConfig);

    return response.data;

};

const RecommendedService = {
    getRecommendedDish
};

export default RecommendedService;