import axios from "axios";
import { base_url } from "../../Utils/baseUrl";
import { config } from "../../Utils/axiosconfig";

const getWalletAmount = async () => {

    const response = await axios.get(`${base_url}users/getWallet`, config);

    return response.data;
};

const WalletService = {
    getWalletAmount,
};

export default WalletService;