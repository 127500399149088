import React, { useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import CloseAccount from '../ProfileModals/closeaccount';
import ProfileSide from './profileSide';
import ProfileHome from './ProfileHome';
import UserOrders from './UserOrders';
import UserChild from './UserChild';
import UserProfile from './UserProfile';
import { useLocation } from 'react-router-dom';
import UserTransaction from './UserTransaction';
import { getWalletAmount } from '../../app/wallet/wallet.slice';
import { useDispatch } from 'react-redux';
import ChangePassword from '../ProfileModals/changePassword';
import Loader from '../Common/Loader';

function MyProfile() {

    const [loader, setLoader] = useState(true);

    const dispatch = useDispatch();
    const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);
    const closeaccountModal = () => setIsAccountModalOpen(false);

    // State for password change
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const closePasswordModal = () => setIsPasswordModalOpen(false);

    const openAccountModal = () => {
        setIsAccountModalOpen(true);
    }

    const openPasswordModal = () => {
        setIsPasswordModalOpen(true);
    }

    const location = useLocation();
    const { isOrder, addChild } = location.state ? location.state : false;
    useEffect(() => {
        dispatch(getWalletAmount());
        setTimeout(() => {
            setLoader(false);
        }, 1000);
    }, [loader])

    if (loader) {
        return <Loader />;
    }
    return (
        <>
            <main>
                <section className="tab_items wow pulse">
                    <div className="container">
                        <div className="row">
                            <ProfileSide openAccountModal={openAccountModal}
                                openPasswordModal={openPasswordModal}
                                isOrder={isOrder}
                                addChild={addChild}
                            />
                            <div className="col-md-9 ">
                                <div className="tab_menuContents ">
                                    <div className="tab-content" id="myTabContent">
                                        <ProfileHome
                                            isOrder={isOrder}
                                            addChild={addChild}
                                        />
                                        {/* update profile div */}
                                        <UserProfile />
                                        {/* child Div */}
                                        <UserChild
                                            addChild={addChild}
                                        />
                                        {/* order div */}
                                        <UserOrders
                                            isOrder={isOrder}
                                        />
                                        {/* Close account section */}
                                        <CloseAccount
                                            isAccountOpen={isAccountModalOpen}
                                            closeAccModal={closeaccountModal}
                                        />
                                        <UserTransaction />
                                        <ChangePassword
                                            isPasswordModalOpen={isPasswordModalOpen}
                                            closePasswordModal={closePasswordModal}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default MyProfile
