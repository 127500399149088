import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { base_url } from "../../Utils/baseUrl";

export const fetchMenu = createAsyncThunk('menu/fetchMenu', async (userData, thunkAPI) => {

    const response = await axios.get(`${base_url}menus?pageSize=${userData.pageSize || ''}&pageNumber=${userData.pageNumber || ''}`);
    return response.data
});
const menuSlice = createSlice({
    name: 'menu',
    initialState: {
        loading: false,
        menu: [],
        error: ''
    },

    // reducer will call here
    extraReducers: builder => {
        builder.addCase(fetchMenu.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchMenu.fulfilled, (state, action) => {
            state.loading = false;
            state.menu = action.payload;
            state.error = '';
        });
        builder.addCase(fetchMenu.rejected, (state, action) => {
            state.loading = false;
            state.menu = [];
            state.error = action.error.message;
        });
    }
});
export default menuSlice.reducer;