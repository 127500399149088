import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { fetchMenuDetails } from '../../app/menudetails/menudetailSlice';
import { base_url, image_url, site_url } from '../../Utils/baseUrl';
import { MENU_IMAGE_PATH } from '../../Utils/baseUrl';
import { fetchMenu } from '../../app/menu/menuSlice';
import Recommendeddish from '../RecommendedDish/recommendeddish';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import { config } from '../../Utils/axiosconfig'

function MenuDetails() {
  const pageNumber = 1;
  const pageSize = 15;

  const [child, setChild] = React.useState(''); 
  const [childlist, setChildlist] = useState(''); 

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const { idMenu } = useParams();
  const dispatch = useDispatch();
  const menuDetails = useSelector((state) => state.menuDetails);

  const user = useSelector((state) => state.user); // Getting the User Data.

  useEffect(() => {
    dispatch(fetchMenuDetails(idMenu));
    window.scrollTo(0, 0)
  }, [idMenu, dispatch]);

  useEffect(() => {
    dispatch(fetchMenu());
  }, [dispatch]);

  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setChild(event.target.value);
    setOpen(false);
  };
  const handleOpen = () => {
    try {
      setOpen(true)
      axios.get(`${base_url}getChild?pageNumber=${pageNumber}&pageSize=${pageSize}`, config).then((res) => {
        setChildlist(res.data.data.child);

      }).catch((err) => {
        console.log(err);
      });
    } catch (error) {
      console.error(error);
    }
  };
  const handleClose = () => setOpen(false);

  return (
    <React.Fragment>
      <main>
        <section className="page-info-sec">
          <div
            className="bg-fixed wow fadeIn"
            data-wow-delay="0.1s"
            style={{ background: `url("${site_url}/assets/img/page-info1.png")` }}
          />
          <div className="bg-fixed img-overlay wow fadeIn" data-wow-delay="0.2s" />
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-12">
                <div className="page-info-block wow fadeInUp" data-wow-delay="0.3s">
                  <h1>Food detail</h1>
                  <div className="btm-line-2 text-center justify-content-center">
                    <span />
                    <span />
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulmtas
                    pulvinar massa, ac volutpat augue finibus in. Pellentesque auctor
                    eget massa nec ornare.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sec-pd rel-z-1">
          <div className="top-bottom-shaps">
            <span className="shap shap-1" />
            <span className="shap shap-2" />
          </div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 mmb-20">
                <div className="img-style-1 wow fadeInLeft">
                  <img src={`${image_url}${MENU_IMAGE_PATH}` + menuDetails?.menuDetails?.data?.MenuImages[0]?.image} alt='not found' />
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <div
                    className="title-1 wow fadeInRight"
                    data-wow-delay="0.1s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.1s",
                      animationName: "fadeInUp"
                    }}
                  >
                    <h1>{menuDetails?.menuDetails?.data?.title}</h1>
                    <div className="btm-line-1">
                      <span />
                      <span />
                    </div>
                    {user?.user?.status && (
                      <h3>Price: {menuDetails?.menuDetails?.data?.price} Kr</h3>
                    )}
                    <div className="mb-20">
                      <h4>Description:</h4>
                      <p dangerouslySetInnerHTML={{ __html: menuDetails?.menuDetails?.data?.description.replace(/<[^>]+>/g, '') }}></p>
                    </div>
                    <div className="mb-20">
                      <h4>Full Ingredients:</h4>
                      <ul>
                        {menuDetails.menuDetails?.data?.Ingredients.map((item, index) => (
                          <li key={index}>{item.title}</li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <h4>Nutritional content per 100g:</h4>
                      <ul>
                        {menuDetails?.menuDetails?.data?.nutrition && typeof menuDetails.menuDetails.data.nutrition === 'object'
                          ? Object.keys(menuDetails.menuDetails.data.nutrition).map((key, index) => (
                            <li key={index}>{key}: {menuDetails.menuDetails.data.nutrition[key]}</li>
                          ))
                          : <p>No data found</p>
                        }
                      </ul>
                    </div>
                    <div className="menu-desc">
                      <div className="menu-desc-head">
                        {user?.user?.status ? (
                          <Link className="btn btn-1" to={'/make-order'}>
                            Make Order
                          </Link>
                        ) : (
                          <Link className="btn btn-1" to={'/login'}>
                            Login For Order
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Select Child
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }} component="div">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Child List</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={child}
                    label="Child"
                    onChange={handleChange}
                  >
                    {(childlist !== undefined && childlist.length > 0) ?
                      childlist.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                        )
                      }) :
                      <MenuItem>No child found</MenuItem>
                    }
                  </Select>
                </FormControl>
              </Typography>
            </Box>
          </Modal>
        </section>
        <Recommendeddish />
      </main>
    </React.Fragment>

  );
}

export default MenuDetails;
