import axios from 'axios';
import { base_url } from "../../Utils/baseUrl";

const getCategories = async (categories) => {
    const requestConfig = {}
    requestConfig.params = {
        search: categories.search,
        pageSize: categories.pageSize,
        pageNumber: categories.pageNumber,
    };

    const response = await axios.get(`${base_url}getCategories`, requestConfig);
    
    return response.data

};

const CategoryService = {
    getCategories
};

export default CategoryService;