import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import RecommendedService from './recommendeddish.service';

export const fetchRecommendedDish = createAsyncThunk('recommendedDish/fetchRecommendedDish', async (recommendedDishData, thunkAPI) => {
    try {
        return await RecommendedService.getRecommendedDish(recommendedDishData);

    } catch (error) {
        return await error.response.data;
    }
});
const recommendedDishSlice = createSlice({
    name: 'recommendedDish',
    initialState: {
        loading: false,
        recommendeddish: [],
        error: ''
    },

    // reducer will call here
    extraReducers: builder => {
        builder.addCase(fetchRecommendedDish.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchRecommendedDish.fulfilled, (state, action) => {
            state.loading = false;
            state.recommendeddish = action.payload;
            state.error = '';
        });
        builder.addCase(fetchRecommendedDish.rejected, (state, action) => {
            state.loading = false;
            state.recommendeddish = [];
            state.error = action.error.message;
        });
    }
});
export default recommendedDishSlice.reducer;