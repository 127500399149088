import Cookies from 'js-cookie';

const token = Cookies.get('token');

export const config = {

    headers: {
        Authorization: `Bearer ${token !== null ? token : ""
            }`,
        Accept: "application/json",
    },
};
