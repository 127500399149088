import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import commonFunction from '../../Utils/commonFunction';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { MenuItem } from '@mui/material';
import axios from 'axios';
import { base_url } from '../../Utils/baseUrl';
import { config } from '../../Utils/axiosconfig';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DefaultPagination from "../../config/Pagination";
import { fetchChild } from '../../app/child/childSlice';
import { useDispatch } from 'react-redux';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('child name is required'),
    schoolId: Yup.string().required('child school is required'),
    classId: Yup.string().required('child class is required'),
});

const AddChild = ({ isAddOpen, closeaddModal, school, classes }) => {

    const dispatch = useDispatch();

    let showChildData = {
        search: '',
        pageNumber: DefaultPagination.PAGE_NUMBER,
        pageSize: DefaultPagination.PAGE_SIZE,
    };

    const handleSubmit = async (values) => {

        const additionalData = {
            status: 1
        }

        const requestData = { ...values, ...additionalData };

        try {
            await axios.post(`${base_url}addChild`, requestData, config).then(res => {
                toast.success(res.data.message);
                // Reset the form after successful submission
                formik.resetForm();
                dispatch(fetchChild(showChildData));
            }).catch(err => {
                console.log(err, 'err');
            })
        } catch (error) {
            console.error('An error occurred while adding a child:', error);
            toast.error('Please wait!! Something went wrong !!');
        }
        closeaddModal();
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            schoolId: '',
            classId: '',
        },
        // enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
    });

    return (
        <Dialog open={isAddOpen} onClose={closeaddModal}>
            <DialogTitle>Add Child</DialogTitle>
            <DialogContent>
                <TextField
                    id="name"
                    label="Child's name"
                    name="name"
                    value={formik.values.name || ''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    fullWidth
                    margin="normal"
                />

                <TextField
                    select
                    id="schoolId"
                    label="Child's school"
                    name="schoolId"
                    value={formik.values.schoolId || ''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.schoolId && Boolean(formik.errors.schoolId)}
                    helperText={formik.touched.schoolId && formik.errors.schoolId}
                    fullWidth
                    margin="normal"
                >
                    {school?.data?.school.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.schoolName}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    select
                    id='classId'
                    label="Select class"
                    name="classId"
                    value={formik.values.classId || ''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.classId && Boolean(formik.errors.classId)}
                    helperText={formik.touched.classId && formik.errors.classId}
                    fullWidth
                    margin="normal"
                >
                    {formik.values.schoolId && ( // Only render class options if school is selected
                        classes?.data?.schoolclass.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.className}
                            </MenuItem>
                        ))
                    )}
                </TextField>

            </DialogContent>
            <DialogActions>
                <Button onClick={closeaddModal} color="primary">
                    Cancel
                </Button>
                <Button onClick={formik.handleSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddChild;
