import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { config } from '../../Utils/axiosconfig';
import axios from 'axios';
import { base_url } from '../../Utils/baseUrl';

export const fetchChild = createAsyncThunk('childs/fetchChild', async (childData, thunkAPI) => {
    try {
        const requestConfig = { ...config };
        if (childData && childData.pageSize && childData.pageNumber) {
            requestConfig.params = {
                pageSize: childData.pageSize,
                pageNumber: childData.pageNumber,
                search: childData.search ? childData.search : '',
            };
        }
        const response = await axios.get(`${base_url}getChild`, requestConfig);
        return response.data;
    } catch (error) {
        return await error.response.data;
    }
});

const childSlice = createSlice({
    name: 'getchild',
    initialState: {
        loading: false,
        getchild: [],
        error: ''
    },

    // reducer will call here
    extraReducers: builder => {
        builder.addCase(fetchChild.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchChild.fulfilled, (state, action) => {
            state.loading = false;
            state.getchild = action.payload;
            state.error = '';
        });
        builder.addCase(fetchChild.rejected, (state, action) => {
            state.loading = false;
            state.getchild = [];
            state.error = action.error.message;
        });
    }
});
export default childSlice.reducer; 