import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMenu } from "../../app/menu/menuSlice";

import { NavLink } from "react-router-dom";
import { image_url } from "../../Utils/baseUrl";
import { MENU_IMAGE_PATH } from "../../Utils/baseUrl";
import Pagination from "../Common/Pagination";
import DefaultPagination from "../../config/Pagination";

function Menu() {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.menu);
  const [userData, setUserData] = useState({
    pageSize: 9,
    pageNumber: DefaultPagination.PAGE_NUMBER,
  });

  useEffect(() => {
    dispatch(fetchMenu(userData));
    // window.scrollTo(0, 0)
  }, [dispatch]);

  // Handle pagination
  const paginationWithPage = (pageNumber) => {
    // setUserData((prevUserData) => ({ ...prevUserData, pageNumber: pageNumber }));
    // dispatch(fetchMenu(userData));
    const updatedUserData = { ...userData, pageNumber: pageNumber };
    setUserData(updatedUserData);
    dispatch(fetchMenu(updatedUserData));
  };
  return (
    <React.Fragment>
      <section className="sec-pd  bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="title-1 title-center wow fadeInUp"
                data-wow-delay="0.2s"
              >
                <h1>Mad Menu</h1>
                <div className="btm-line-1 text-center justify-content-center">
                  <span />
                  <span />
                </div>
                <p>
                  Alle Vores{" "}
                  <span style={{ textDecoration: "underline" }}>Skinke</span>,
                  <span style={{ textDecoration: "underline" }}>Pepperoni</span>{" "}
                  og <span style={{ textDecoration: "underline" }}>Bacon</span>{" "}
                  er <span style={{ textDecoration: "underline" }}>Halal</span>{" "}
                  Kalkun/Kalve
                </p>
              </div>
            </div>
          </div>
          <div className="row wow fadeInUp">
            {menu?.menu?.data?.menus !== undefined &&
            menu?.menu?.data?.menus.length > 0 ? (
              menu?.menu?.data?.menus.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="col-lg-4 col-md-6 col-sm-6 d-flex"
                  >
                    <div className="menu-card">
                      <div className="menu-card-img">
                        <img
                          src={
                            item?.MenuImages[0]?.image
                              ? `${image_url}${MENU_IMAGE_PATH}` +
                                item?.MenuImages[0]?.image
                              : ""
                          }
                          alt="not found"
                        />
                      </div>
                      <div className="menu-desc">
                        <div className="menu-desc-head">
                          <h5>{item.title.replace(/<[^>]+>/g, "")}</h5>
                        </div>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: item.description.replace(/<[^>]+>/g, ""),
                          }}
                        ></p>
                        <NavLink
                          className="btn btn-1"
                          to={`/menuDetails/${item.idMenu}`}
                        >
                          View more
                        </NavLink>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No Menu found</p>
            )}
          </div>
          {/* Pagination component */}
          <Pagination
            totalPage={menu.menu?.data?.pagination?.totalPages}
            currentPage={menu.menu?.data?.pagination?.currentPage}
            fullPagination={paginationWithPage}
          />
        </div>
      </section>
    </React.Fragment>
  );
}

export default Menu;
