import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { base_url } from "../../Utils/baseUrl";

export const fetchMenuByCategory = createAsyncThunk('menuByCategory', async (id) => {
    return axios.get(`${base_url}getMenuByCategory/${id}`)
    .then(res => res.data)
});

 
const getMenubyCategory = createSlice({
    name: 'getMenubyCategory',
    initialState: {
        loading: false,
        getMenubyCategory: [],
        error: ''
    },

    // reducer will call here
    extraReducers: builder => {
        builder.addCase(fetchMenuByCategory.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchMenuByCategory.fulfilled, (state, action) => {
            state.loading = false;
            state.getMenubyCategory = action.payload;
            state.error = '';
        });
        builder.addCase(fetchMenuByCategory.rejected, (state, action) => {
            state.loading = false;
            state.getMenubyCategory = [];
            state.error = action.error.message;
        });
    }
});

export default getMenubyCategory.reducer;