import React, { useState, useEffect } from 'react';

const Pagination = (props) => {
  const [pageNumbers, setPageNumbers] = useState([]);

  useEffect(() => {
    // Destructure props to get the required values
    const { totalPage, currentPage } = props;

    // Create an array to store the page numbers to be rendered in the pagination
    const updatedPageNumbers = [];

    // Check if totalPage is valid and greater than 0
    if (totalPage !== null && totalPage !== undefined && totalPage > 0) {
      // If there's only one page, add it to the updatedPageNumbers array
      if (totalPage === 1) {
        updatedPageNumbers.push(1);
      } else {
        // If there are multiple pages, create a range of page numbers around the current page
        for (let i = 1; i <= Math.ceil(totalPage); i++) {
          if (i === currentPage) {
            // For the current page, calculate the previous and next page numbers
            const j = i - 1;
            const k = i + 1;

            // Add the page numbers to the updatedPageNumbers array based on the current page position
            if (i === 1) {
              updatedPageNumbers.push(i);
              updatedPageNumbers.push(k);
            } else if (i === Math.ceil(totalPage)) {
              updatedPageNumbers.push(j);
              updatedPageNumbers.push(i);
            } else {
              updatedPageNumbers.push(j);
              updatedPageNumbers.push(i);
              updatedPageNumbers.push(k);
            }
          }
        }
      }
    }

    // Update the state with the updatedPageNumbers array
    setPageNumbers(updatedPageNumbers);
  }, [props.totalPage, props.currentPage]);

  // Map through the pageNumbers array to render the page number links
  const renderPageNumbers = pageNumbers.map((number) => {
    // Set CSS class based on whether the page number is the current page or not
    const tempClass =
      props.currentPage === number
        ? 'paginate_button page-item active'
        : 'paginate_button page-item';

    // Render each page number link
    return (
      <li key={number} className={tempClass}>
        <a

          aria-controls="dataTable"
          data-dt-idx="1"
          tabIndex="0"
          className="page-link"
          // Call the fullPagination function with the selected page number when the link is clicked
          onClick={() => props.fullPagination(number)}
        >
          {number}
        </a>
      </li>
    );
  });

  // Set the CSS class for the first page link
  const firstPageClass =
    props.currentPage === 1
      ? 'paginate_button page-item next disabled'
      : 'paginate_button page-item next';

  // Set the CSS class for the last page link
  const lastPageClass =
    props.currentPage === props.totalPage
      ? 'paginate_button page-item next disabled'
      : 'paginate_button page-item next';

  return (
    <div className="row">
      <div className="col-sm-12 col-md-5"></div>
      <div className="col-sm-12 col-md-7">
        <div
          className="dataTables_paginate paging_simple_numbers"
          id="dataTable_paginate"
        >
          <ul className="pagination">
            {/* Render the "First" page link */}
            <li className={firstPageClass} id="dataTable_previous">
              <a
                key={0}

                aria-controls="dataTable"
                data-dt-idx="0"
                tabIndex="0"
                className="page-link"
                // Call the fullPagination function with page number 1 when the link is clicked
                onClick={() => props.fullPagination(1)}
              >
                First
              </a>
            </li>

            {/* Render the page number links */}
            {renderPageNumbers !== undefined && renderPageNumbers}

            {/* Render the "Last" page link */}
            <li className={lastPageClass} id="dataTable_next">
              <a
                key={props.totalPage}

                aria-controls="dataTable"
                data-dt-idx="1"
                tabIndex="0"
                className="page-link"
                // Call the fullPagination function with the last page number when the link is clicked
                onClick={() => props.fullPagination(props.totalPage)}
              >
                Last
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
