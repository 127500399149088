import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import WalletService from "./wallet.services";

export const getWalletAmount = createAsyncThunk(
    "wallet/get-amount",
    async (walletData, thunkAPI) => {
        try {
            return await WalletService.getWalletAmount(walletData);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const resetState = createAction("RevertAll");

const initialState = {
    amount: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
};

export const WalletSlice = createSlice({
    name: "wallet",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getWalletAmount.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getWalletAmount.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.amount = action.payload.data;
                state.message = action.payload.message
            })
            .addCase(getWalletAmount.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(resetState, () => initialState);
    },
});

export default WalletSlice.reducer;
