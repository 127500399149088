import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getSuccessTransaction } from "../../app/transaction/Transaction.slice";
import { getWalletAmount } from "../../app/wallet/wallet.slice";

const SuccessPayment = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const transactionId = searchParams.get("transactionId");
  const user = useSelector((state) => state.user);
  const transactions = useSelector((state) => state.Transaction);

  const UserId = user?.user?.data?.idUser;

  //   useEffect(() => {
  //     const data = {
  //       idUser: UserId,
  //       transactionId: transactionId,
  //     };
  //     if (UserId) {
  //       dispatch(getSuccessTransaction(data));
  //       dispatch(getWalletAmount());
  //     }
  //   }, [dispatch, UserId]);
  useEffect(() => {
    if (!transactionId) {
      window.location.href = "/";
      return;
    }

    const data = {
      idUser: UserId,
      transactionId: transactionId,
    };

    if (UserId) {
      dispatch(getSuccessTransaction(data))
        .then(() => {
          return dispatch(getWalletAmount());
        })
        .then(() => {
          setTimeout(() => {
            window.location.href = "/myprofile";
          }, 3000);
        })
        .catch((err) => {
          console.error("Error during payment processing:", err);
          window.location.href = "/";
        });
    }
  }, [dispatch, UserId, transactionId]);

  if (!transactionId) {
    navigate("/");
  }

  return (
    <>
      <div className="payment-container">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="message-box _success">
                <i className="fa fa-check-circle" aria-hidden="true" />
                <h2> Your payment was successful </h2>
                <p>
                  {" "}
                  Thank you for your payment. we will <br />
                  be in contact with more details shortly{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <Link to="/" className="btn btn-1">
          Continue Ordering
        </Link>
      </div>
    </>
  );
};

export default SuccessPayment;
