import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { base_url } from "../../Utils/baseUrl";

export const fetchContactDetails = createAsyncThunk('contact/fetchContactDetails', async () => {
    return axios.get(`${base_url}contactus`)
        .then(res => res.data)
});
const contactSlice = createSlice({
    name: 'contact',
    initialState: {
        loading: false,
        contact: [],
        error: ''
    },

    // reducer will call here
    extraReducers: builder => {
        builder.addCase(fetchContactDetails.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchContactDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.contact = action.payload;
            state.error = '';
        });
        builder.addCase(fetchContactDetails.rejected, (state, action) => {
            state.loading = false;
            state.contact = [];
            state.error = action.error.message;
        });
    }
});
export default contactSlice.reducer;