import axios from "axios";
import { base_url } from "../../Utils/baseUrl";
import Cookies from "js-cookie";

const createLogin = async (user) => {

    const { email, password } = user;
    const userData = {
        email,
        password
    };
    const response = await axios.post(`${base_url}auth/login`, userData);
    Cookies.set('token', response.data.data.token, { path: '/' });
    return response.data;
};

const userDetail = async (config) => {
    const response = await axios.get(`${base_url}auth/userDetails`, config);
    return response.data;
};

const userLogout = async (config) => {
    const response = await axios.get(`${base_url}auth/logout`, config);
    return response.data;
};

const LoginService = {
    createLogin,
    userDetail,
    userLogout
};

export default LoginService;