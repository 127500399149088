import axios from "axios";
import { base_url } from "../../Utils/baseUrl";
import { config } from "../../Utils/axiosconfig";

const getTransaction = async (data) => {

    const requestConfig = { ...config };
    if (data && data.pageSize && data.pageNumber) {
        requestConfig.params = {
            pageSize: data.pageSize,
            pageNumber: data.pageNumber,
            search: data.search ? data.search : '',
        };
    }
    const response = await axios.get(`${base_url}transaction/getlist`, requestConfig);

    return response.data;
};

const getSuccessTransaction = async (data) => {
    const requestConfig = { ...config };
    const response = await axios.get(`${base_url}transaction/quick_success/?idUser=${data.idUser}&transactionId=${data.transactionId}`, requestConfig);
    return response.data;
};

const getCancelTransaction = async (data) => {
    const requestConfig = { ...config };
    const response = await axios.get(`${base_url}transaction/quick_cancel/?transactionId=${data.transactionId}`, requestConfig);
    return response.data;
};

const TransactionService = {
    getTransaction,
    getSuccessTransaction,
    getCancelTransaction,
};

export default TransactionService;