import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./Contact.css"; // Import your CSS file
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { base_url } from "../../Utils/baseUrl";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";
import { child, Complain, Question, website } from "../../Utils/constants";
import commonFunction from "../../Utils/commonFunction";
import { fetchChild } from "../../app/child/childSlice";
import Loader from "../Common/Loader";

export const Contact = () => {
  const [school, setSchool] = useState();
  const [childDetails, setChildDetails] = useState({
    childName: "",
    schoolName: "",
    className: "",
  });
  // Use state to manage userData
  const [userData, setUserData] = useState({
    search: "",
    pageSize: 100,
    pageNumber: 1,
  });
  const [loader, setLoader] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 1000);
    if (user?.user?.data) {
      dispatch(fetchChild(userData));
    }
    if (!school) {
      commonFunction.getSchools().then((res) => {
        setSchool(res.data);
      });
    }
    window.scrollTo(0, 0);
  }, [school]);

  // const child = useSelector((state) => state.child);
  const user = useSelector((state) => state.user);

  // State to store the reCAPTCHA response
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  // Handle the reCAPTCHA onChange event
  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const contactDetails = useSelector((state) => state.contact);

  const validationSchema = Yup.object({
    name: Yup.string().required("Full name is required"),
    email: Yup.string().email().required("Email is required"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      option: "",
      message: "",
    },
    onSubmit: (values) => {
      try {
        if (recaptchaValue) {
          setLoader(true);
          axios
            .post(`${base_url}createInquiry`, values)
            .then((res) => {
              toast.success(res.data.message);
              // Reset the form and RecaptchaValue after successful submission
              formik.resetForm();
              setChildDetails({
                childName: "",
                schoolName: "",
                className: "",
              });
              setLoader(false);
            })
            .catch((err) => {
              console.log(err);
              setLoader(false);
            });
          // send inquery email
          commonFunction
            .sendCustomMail({ values, childDetails })
            .then((res) => {
              console.log(res, "res");
            });
        } else {
          toast.error("Please wait!! Check the google varification !!");
        }
      } catch {
        toast.error("Please wait!! Something went wrong !!");
      }
    },
    validationSchema,
  });

  if (loader) {
    return <Loader />;
  }

  return (
    <>
      <main>
        <section className="page-info-sec">
          <div
            className="bg-fixed wow fadeIn"
            data-wow-delay="0.1s"
            style={{ background: 'url("assets/img/page-info1.png")' }}
          />
          <div
            className="bg-fixed img-overlay wow fadeIn"
            data-wow-delay="0.2s"
          />
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-12">
                <div
                  className="page-info-block wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <h1>Contact us</h1>
                  <div className="btm-line-2 text-center justify-content-center">
                    <span />
                    <span />
                  </div>
                  <p>
                    If you have any questions for us, be it criticism, praise,
                    ideas or complaints, please write, we are always happy to be
                    able to help
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sec-pd rel-z-1">
          <div className="top-bottom-shaps">
            <span className="shap shap-1" />
            <span className="shap shap-2" />
          </div>
          <div className="container">
            <div className="row">
              <div
                className="col-sm-4 mmb-20 d-flex wow fadeInDown"
                data-wow-delay="0.2s"
              >
                <div className="c-info">
                  <span className="c-icon">
                    <i className="fa fa-phone" aria-hidden="true" />
                  </span>
                  <p className="p-head">Phone</p>
                  <p>
                    {contactDetails.contact.data?.phone.replace(
                      /(\d{3})(\d{3})(\d{4})/,
                      "$1-$2-$3"
                    )}
                  </p>
                </div>
              </div>
              <div
                className="col-sm-4 mmb-20 d-flex wow fadeInDown"
                data-wow-delay="0.2s"
              >
                <div className="c-info">
                  <span className="c-icon">
                    <i className="fa fa-envelope" aria-hidden="true" />
                  </span>
                  <p className="p-head">Email</p>
                  <p>{contactDetails.contact.data?.email}</p>
                </div>
              </div>
              <div
                className="col-sm-4 mmb-20 d-flex wow fadeInDown"
                data-wow-delay="0.2s"
              >
                <div className="c-info">
                  <span className="c-icon">
                    <i className="fa fa-map-marker" aria-hidden="true" />
                  </span>
                  <p className="p-head">Location</p>
                  <p>{contactDetails.contact.data?.location}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sec-pd rel-z-1 pb-0">
          <div className="get-touch-bg">
            <div className="inner-get-touch">
              <div
                className="title-1 title-center wow fadeInDown"
                data-wow-delay="0.2s"
              >
                <h1>Get in Touch</h1>
                <div className="btm-line-1 text-center justify-content-center">
                  <span />
                  <span />
                </div>
              </div>
              <form
                className="wow fadeIn"
                data-wow-delay="0.2s"
                onSubmit={formik.handleSubmit}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="formGroupExampleInput"
                        placeholder="Full Name"
                        name="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <div className="error">{formik.errors.name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="error">{formik.errors.email}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <select
                    className="form-control"
                    name="option"
                    id="exampleFormControlSelect1"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.option}
                  >
                    <option value="">Select Option</option>
                    <option value="child">{child}</option>
                    <option value="complain">{Complain}</option>
                    <option value="website">{website}</option>
                    <option value="other question">{Question}</option>
                  </select>
                </div>
                {formik.values.option === "child" && (
                  <>
                    <div className="form-group">
                      <label htmlFor="childName">Child Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="childName"
                        name="childName"
                        onChange={(e) =>
                          setChildDetails({
                            ...childDetails,
                            childName: e.target.value,
                          })
                        }
                        value={childDetails.childName}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="schoolName">School Name</label>
                      <select
                        className="form-control"
                        id="schoolName"
                        name="schoolName"
                        onChange={(e) =>
                          setChildDetails({
                            ...childDetails,
                            schoolName: e.target.value,
                          })
                        }
                        value={childDetails.schoolName}
                      >
                        <option value="">Select School</option>
                        {school?.data?.school?.map((school, index) => (
                          <option key={index} value={school.schoolName}>
                            {school.schoolName}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="className">Class</label>
                      <input
                        type="text"
                        className="form-control"
                        id="className"
                        name="className"
                        onChange={(e) =>
                          setChildDetails({
                            ...childDetails,
                            className: e.target.value,
                          })
                        }
                        value={childDetails.className}
                      />
                    </div>
                  </>
                )}
                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="message"
                    id="exampleFormControlTextarea1"
                    rows={3}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                  />
                </div>
                {/* Add the reCAPTCHA component */}
                <ReCAPTCHA
                  sitekey="6LeNl04pAAAAAFfM11AJEAhhO0B7WAmSkb4EuNz7"
                  onChange={handleRecaptchaChange}
                  onExpired={() => {
                    setRecaptchaValue(null);
                  }}
                />
                <button type="submit" className="btn btn-1 mt-50">
                  {" "}
                  Submit
                </button>
              </form>
            </div>
          </div>
        </section>
        {/* {as a task client ask to comment it for now} */}
        {/* <div className="w-100 wow fadeIn map-wrap" data-wow-delay="0.2s">
                    <iframe width="100%" title='World Map' height={300} frameBorder={0} scrolling="no" marginHeight={0} marginWidth={0} src="https://maps.google.com/maps?width=100%25&height=600&hl=en&q=+()&t=&z=14&ie=UTF8&iwloc=B&output=embed" ></iframe>
                </div> */}
      </main>
    </>
  );
};
