import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { fetchContactDetails } from '../../../app/contact/contactSlice';
import { useEffect } from 'react';
import { site_url } from '../../../Utils/baseUrl';

export const Footer = () => {

    const dispatch = useDispatch();
    const contactDetails = useSelector((state) => state.contact);

    useEffect(() => {
        dispatch(fetchContactDetails());
    }, [dispatch]);

    return (
        <>
            {/* footer start */}
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-sbm-1">
                            <div className="f-logo wow fadeIn" data-wow-delay="0.2s">
                                <img src={`${site_url}/assets/img/f-logo.png`} alt='not found' />
                            </div>
                            <ul className="footer-list icon-list">
                                <li className="wow fadeInUp" data-wow-delay="0.7s">
                                    <Link><i className="fas fa-map-marker-alt" /> {contactDetails.contact.data?.location}</Link>
                                </li>
                                <li className="wow fadeInUp" data-wow-delay="0.9s">
                                    <Link><i className="fas fa-envelope" /> {contactDetails.contact.data?.email} </Link>
                                </li>
                                <li className="wow fadeInUp" data-wow-delay="0.8s">
                                    <Link><i className="fas fa-phone" /> {contactDetails.contact.data?.phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")} </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="footer-rightwrapper">
                            <div className="footer-right-wrap">
                                <div className="col-sbm-2">
                                    <h2 className="footer-title wow fadeInUp" data-wow-delay="0.4s">Quick Links</h2>
                                    <ul className="footer-list">
                                        <li className="wow fadeInUp" data-wow-delay="0.7s">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="wow fadeInUp" data-wow-delay="0.8s">
                                            <Link to="/about">About</Link>
                                        </li>
                                        <li className="wow fadeInUp" data-wow-delay="0.9s">
                                            <Link to="/menu">Menu</Link>
                                        </li>
                                        <li className="wow fadeInUp" data-wow-delay="1s">
                                            <Link to="/contact">Contact Us</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sbm-3">
                                    <h2 className="footer-title wow fadeInUp" data-wow-delay="0.5s">Find Events</h2>
                                    <ul className="footer-list">
                                        <li className="wow fadeInUp" data-wow-delay="0.7s">
                                            <Link href="#">Privacy policy</Link>
                                        </li>
                                        <li className="wow fadeInUp" data-wow-delay="0.8s">
                                            <Link to="/terms">Terms &amp; Conditions</Link>
                                        </li>
                                        <li className="wow fadeInUp" data-wow-delay="0.9s">
                                            <Link href="#">Cookie Policy</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sbm-4">
                                    <h2 className="footer-title wow fadeInUp" data-wow-delay="0.6s">We accept the following cards:
                                    </h2>
                                    <ul className="card-list">
                                        <li className="wow fadeInUp" data-wow-delay="0.7s">
                                            <img src={`${site_url}/assets/img/cc-1.png`} alt='not found' />
                                        </li>
                                        <li className="wow fadeInUp" data-wow-delay="0.8s">
                                            <img src={`${site_url}/assets/img/cc-2.png`} alt='not found' />
                                        </li>
                                        <li className="wow fadeInUp" data-wow-delay="0.9s">
                                            <img src={`${site_url}/assets/img/cc-3.png`} alt='not found' />
                                        </li>
                                        <li className="wow fadeInUp" data-wow-delay="0.9s">
                                            <img src={`${site_url}/assets/img/cc-4.png`} alt='not found' />
                                        </li>
                                        <li className="wow fadeInUp" data-wow-delay="0.9s">
                                            <img src={`${site_url}/assets/img/cc-5.png`} alt='not found' />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-sbm-1 follow-us">
                            <h2 className="footer-title wow fadeInUp" data-wow-delay="0.5s">Follow Us:</h2>
                            <ul className="social-list-1 d-inline-flex">
                                <li className="wow fadeInUp" data-wow-delay="0.7s"><Link>
                                    <i className="fab fa-facebook-f" />
                                </Link></li>
                                <li className="wow fadeInUp" data-wow-delay="0.8s"><Link>
                                    <i className="fab fa-instagram" />
                                </Link></li>
                                <li className="wow fadeInUp" data-wow-delay="0.9s"><Link>
                                    <i className="fab fa-linkedin-in" />
                                </Link></li>
                                <li className="wow fadeInUp" data-wow-delay="1s"><Link>
                                    <i className="fab fa-twitter" />
                                </Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="copyright wow fadeIn" data-wow-delay="0.2s">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <p>Copyright © 2024 OnlineSkolemad. All right reserved 2024</p>
                        </div>
                        <div className="col-md-6 kontrol">
                            <img src={`${site_url}/assets/img/kontrol.png`} alt='not found' />
                        </div>
                    </div>
                </div>
            </div>
            {/* footer end */}
        </>
    )
}
