import React, { useEffect } from 'react'
import Categories from '../Categories/Categories';

function MenuPage() {
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <main>
        <section className="page-info-sec">
          <div
            className="bg-fixed wow fadeIn"
            data-wow-delay="0.1s"
            style={{ background: 'url("assets/img/page-info1.png")' }}
          />
          <div className="bg-fixed img-overlay wow fadeIn" data-wow-delay="0.2s" />
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-12">
                <div className="page-info-block wow fadeInUp" data-wow-delay="0.3s">
                  <h1>Menu</h1>
                  <div className="btm-line-2 text-center justify-content-center">
                    <span />
                    <span />
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulmtas
                    pulvinar massa, ac volutpat augue finibus in. Pellentesque auctor
                    eget massa nec ornare.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Categories />
      </main>
    </>
  )
}

export default MenuPage;