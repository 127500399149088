import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import axios from 'axios';
import { base_url } from '../../Utils/baseUrl';
import { config } from '../../Utils/axiosconfig';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { userLogout } from '../../app/login/login.slice';


const CloseAccount = ({ isAccountOpen, closeAccModal }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubmit = async () => {

        try {
            const response = await axios.patch(`${base_url}users/reqdeleteUserAccount`, {}, config);
            toast.success(response.data.message);
            Cookies.remove('token');
            dispatch(userLogout(config));
            closeAccModal();
            setTimeout(() => {
                navigate("/login");
            }, 2000);
        } catch (error) {
            console.error('An error occurred while Deleting your account:', error);
            toast.error('Please wait!! Something went wrong !!');
        }
    };


    return (
        <Dialog open={isAccountOpen} onClose={closeAccModal}>
            <DialogTitle>Close your account</DialogTitle>
            <DialogContent>
                You requested to close your account. Please note that by doing so the account cannot be recreated. If you have money in your account, DKK 50 will be taken from it and the rest will be returned to your last used card. If you have no money in your food account or less than DKK 50, you will not be charged anything. So we recommend that you use your money before choosing to close it.
                Request to close account?
            </DialogContent>
            <DialogActions>
                <Button onClick={closeAccModal} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CloseAccount;
