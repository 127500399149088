import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchRecommendedDish } from '../../app/recommendeddish/recommendeddishSlice';
import DefaultPagination from "../../config/Pagination";
import { MENU_IMAGE_PATH, image_url } from '../../Utils/baseUrl';
import { NavLink } from 'react-router-dom';

function Recommendeddish() {

  const dispatch = useDispatch();

  let recommendedDishData = {
    search: '',
    pageNumber: DefaultPagination.PAGE_NUMBER,
    pageSize: 6,
  };

  useEffect(() => {
    dispatch(fetchRecommendedDish(recommendedDishData));
  }, [recommendedDishData.pageNumber, recommendedDishData.pageSize, dispatch]);

  const dishes = useSelector((state) => state.recommendedDishes?.recommendeddish);

  return (
    <React.Fragment>
      <section className="sec-pd  bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="title-1 title-center wow fadeInUp"
                data-wow-delay="0.2s"
              >
                <h1>Recommended Dish</h1>
                <div className="btm-line-1 text-center justify-content-center">
                  <span />
                  <span />
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                  suada nisi. Donec suscipit varius risus, non luctus nisl euismod
                  sit amet. Quisque
                </p>
              </div>
            </div>
          </div>
          <div className="row wow fadeInUp">
            {dishes?.data?.menus.map((item, index) => (
              <div className="col-lg-4 col-md-6 col-sm-6 d-flx" key={index}>
                <div className="menu-card">
                  <div className="menu-card-img">
                    <img src={`${image_url}${MENU_IMAGE_PATH}` + item?.MenuImages[0]?.image} alt='not found' />
                  </div>
                  <div className="menu-desc">
                    <div className="menu-desc-head">
                      <h5 className="">{item.title}</h5>
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: item.description.replace(/<[^>]+>/g, '') }}></p>
                    <NavLink className="btn btn-1" to={`/menuDetails/${item.idMenu}`}>
                      View more
                    </NavLink>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default Recommendeddish