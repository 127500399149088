import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import LoginService from "./login.services";

export const createLogin = createAsyncThunk(
    "user/create-login",
    async (UserData, thunkAPI) => {
        try {
            return await LoginService.createLogin(UserData);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const userDetail = createAsyncThunk(
    "user/login-detail",
    async (UserData, thunkAPI) => {
        try {
            return await LoginService.userDetail(UserData);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const userLogout = createAsyncThunk(
    "user/logout",
    async (UserData, thunkAPI) => {
        
        try {
            return await LoginService.userLogout(UserData);
        } catch (error) {
            return await error.response.data;
        }
    }
);


export const resetState = createAction("RevertAll");

const initialState = {
    user: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
};

export const loginslice = createSlice({
    name: "user",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(createLogin.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createLogin.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.user = action.payload;
            })
            .addCase(createLogin.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(userDetail.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(userDetail.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.user = action.payload;
            })
            .addCase(userDetail.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })

            .addCase(userLogout.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(userLogout.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.user = '';
            })
            .addCase(userLogout.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = '';
            })

            .addCase(resetState, () => initialState);
    },
});


export default loginslice.reducer;