import { Navigate, Route, Routes } from "react-router-dom";
import { MainLayout } from "./Components/MainLayout";
import { Faq } from "./Components/Faq/Faq";
import { Contact } from "./Components/Contact_us/Contact";
import About from "./Components/About_us/About";
import Home from "./Components/Home/Home";
import MenuPage from "./Components/MenuPage/menupage";
import MenuDetails from "./Components/MenuDetails/menuDetails";
import Login from "./Components/Login/login";
import SignUp from "./Components/Signup/signup";
import VerifyOtp from "./Components/Verify_otp/verifyOtp";
import ForgotPassowrd from "./Components/Forgot_password/forgotpassword";
import Resetpassword from "./Components/Forgot_password/resetpassword";
import MakeOrder from "./Components/Order/makeOrder";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";
import MenuByCategory from "./Components/MenuByCategory/menubycategory";
import MyProfile from "./Components/Profile/myprofile";
import { useSelector } from "react-redux";
import ProtectedRoute from "./Components/Login/protectedroute";
import Payment from "./Components/Payments/payment";
import ErrorPage from "./Components/Pagenotfound/errorpage";
import Terms from "./Components/Common/Terms";
import SuccessPayment from "./Components/Payments/SuccessPayment";
import CancelPayment from "./Components/Payments/CancelPayment";

function App() {
  const user = useSelector((state) => state.user);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="faq" element={<Faq />} />
          <Route path="contact" element={<Contact />} />
          <Route path="about" element={<About />} />
          <Route path="terms" element={<Terms />} />
          <Route path="menu" element={<MenuPage />} />
          <Route path="menuDetails/:idMenu" element={<MenuDetails />} />
          <Route
            path="menubyCategory/:idCategory"
            element={<MenuByCategory />}
          />
          <Route
            path="login"
            element={
              user && user.user && user.user.status ? (
                <Navigate to="/myprofile" />
              ) : (
                <Login />
              )
            }
          />
          <Route path="signup" element={<SignUp />} />
          <Route path="verifyOtp" element={<VerifyOtp />} />
          <Route path="forgot-password" element={<ForgotPassowrd />} />
          <Route path="reset-password" element={<Resetpassword />} />
          <Route path="make-order" element={<MakeOrder />} />
          <Route
            path="login"
            element={
              user && user.user.status ? (
                <Navigate to="/myprofile" />
              ) : (
                <Login />
              )
            }
          />
          <Route path="myprofile" element={<ProtectedRoute />}>
            <Route index element={<MyProfile />} />
          </Route>
          <Route path="make-payment" element={<Payment />} />
          <Route path="payment-success" element={<SuccessPayment />} />
          <Route path="payment-cancel" element={<CancelPayment />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  );
}

export default App;
