import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getCancelTransaction } from '../../app/transaction/Transaction.slice';
import { getWalletAmount } from '../../app/wallet/wallet.slice';

const CancelPayment = () => {
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const transactionId = searchParams.get('transactionId');

    const data = {
        transactionId: transactionId
    }

    useEffect(() => {
        if (transactionId) {
            dispatch(getCancelTransaction(data));
            dispatch(getWalletAmount());
        } else {
            toast.error('Something Went Wrong. Please try again.');
        }
    }, [dispatch])

    if (!transactionId) {
        navigate('/')
    }

    return (
        <div className="payment-container">
            <div className="row justify-content-center">
                <div className="col-md-8">
                    <div className="message-box _success _failed">
                        <i className="fa fa-times-circle" aria-hidden="true" />
                        <h2> Your payment failed </h2>
                        <p>  Try again later </p>
                    </div>
                </div>
            </div>
            <Link to='/' class="btn btn-1">Try Agian</Link>
        </div>
    )
}

export default CancelPayment