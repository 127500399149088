import React, { useEffect } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import axios from "axios";
import { useFormik } from "formik";
import { base_url } from "../../Utils/baseUrl";
import { useLocation, useNavigate } from "react-router-dom";
import commonFunction from "../../Utils/commonFunction";

function Resetpassword() {
    const location = useLocation();

    const navigate = useNavigate();
    const validationSchema = yup.object({
        otp: yup.string().required("OTP is required"),
        email: yup.string().email("Invalid email").required("Email is required"),
        password: yup
            .string()
            .required("Password is required")
            .min(8, "Password is too short - should be 8 chars minimum")
            .matches(/[a-zA-Z]/, "Password can only contain Latin letters"),
    });

    const formik = useFormik({
        initialValues: {
            otp: "",
            email: location.state.email,
            password: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            try {
                axios
                    .post(`${base_url}/auth/reset-password`, values)
                    .then((res) => {
                        toast.success(res.data.message, { autoClose: 7000 });
                        setTimeout(() => {
                            navigate("/login");
                        }, 2000);
                    })
                    .catch((err) => {
                        toast.error(err.response.data.message);
                        formik.resetForm();
                    });
            } catch (error) {
                toast.error("Please wait!! Something went wrong !!");
            }
        },
    });

    const handleResendOtp = async () => {
        const data = {
            email: location.state.email,
        };
        const response = await commonFunction.sendOtp(data);
        if (response.status === 200) {
            toast(response.data.message);
        }
    };

    return (
        <>
            <main>
                <section className="login-sec signup-sec wow pulse">
                    <div className="container">
                        <div className="login-inner">
                            <div className="row">
                                <div className="col-md-6">
                                    <form
                                        className="form_elements"
                                        onSubmit={formik.handleSubmit}
                                    >
                                        <div className="form-title">
                                            <h2> Reset Password</h2>
                                        </div>
                                        <div className="form-group ">
                                            <div className="label_wrap">
                                                <label htmlFor="email">Email ID</label>
                                                <abbr>*</abbr>
                                            </div>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                name="email"
                                                placeholder="Email"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.email}
                                                disabled
                                            />
                                            {formik.touched.email && formik.errors.email ? (
                                                <div className="error-message">
                                                    {formik.errors.email}
                                                </div>
                                            ) : null}
                                        </div>
                                        {/* OTP Field */}
                                        <div className="form-group ">
                                            <div className="label_wrap">
                                                <label htmlFor="otp">OTP</label>
                                                <abbr>*</abbr>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="otp"
                                                name="otp"
                                                placeholder="Enter OTP Numbers received from mail"
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^[0-9]*$/.test(value)) {
                                                        formik.handleChange(e);
                                                    }
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.otp}
                                                pattern="[0-9]*"
                                            />
                                            {formik.touched.otp && formik.errors.otp ? (
                                                <div className="error-message">{formik.errors.otp}</div>
                                            ) : null}
                                            <p className="text-info" style={{ fontSize: "14px" }}>
                                                If OTP is not received, please check your spam mail or
                                                click on the "Resend OTP" button below.
                                            </p>
                                        </div>
                                        <div className="form-group ">
                                            <div className="label_wrap">
                                                <label htmlFor="password">Password</label>
                                                <abbr>*</abbr>
                                            </div>
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="password"
                                                name="password"
                                                placeholder="Enter new Password"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.password}
                                            />
                                            {formik.touched.password && formik.errors.password ? (
                                                <div className="error-message">
                                                    {formik.errors.password}
                                                </div>
                                            ) : null}
                                        </div>

                                        <span
                                            style={{ cursor: "pointer" }}
                                            className="nav-link"
                                            onClick={handleResendOtp}
                                        >
                                            Resend Otp
                                        </span>
                                        <div className="btn_submit">
                                            <button
                                                type="submit"
                                                className="btn btn-1 w-100"
                                                disabled={formik.isSubmitting}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-6">
                                    <div className="reset-img">
                                        {/* Your other content goes here */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default Resetpassword;
