import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { fetchMenuByCategory } from '../../app/menubycategory/menubycategorySlice';
import { MENU_IMAGE_PATH, image_url, site_url } from '../../Utils/baseUrl';

function MenuByCategory() {

    const { idCategory } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchMenuByCategory(idCategory));
        window.scrollTo(0, 0)
    }, [idCategory, dispatch]);

    const menus = useSelector((state) => state.menuByCategory);

    return (
        <React.Fragment>
            <main>
                <section className="page-info-sec">
                    <div
                        className="bg-fixed wow fadeIn"
                        data-wow-delay="0.1s"
                        style={{ background: `url("${site_url}/assets/img/page-info1.png")` }}
                    />
                    <div className="bg-fixed img-overlay wow fadeIn" data-wow-delay="0.2s" />
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-md-12">
                                <div className="page-info-block wow fadeInUp" data-wow-delay="0.3s">
                                    <h1>Category Details</h1>
                                    <div className="btm-line-2 text-center justify-content-center">
                                        <span />
                                        <span />
                                    </div>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulmtas
                                        pulvinar massa, ac volutpat augue finibus in. Pellentesque auctor
                                        eget massa nec ornare.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="sec-pd  bg-gray">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div
                                    className="title-1 title-center wow fadeInUp"
                                    data-wow-delay="0.2s"
                                >
                                    <h1>Menus in Category</h1>
                                    <div className="btm-line-1 text-center justify-content-center">
                                        <span />
                                        <span />
                                    </div>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                                        suada nisi. Donec suscipit varius risus, non luctus nisl euismod
                                        sit amet. Quisque
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row wow fadeInUp">
                            {menus.getMenubyCategory?.data?.menus.map((item, index) => (
                                <div className="col-lg-4 col-md-6 col-sm-6 d-flx" key={index}>
                                    <div className="menu-card">
                                        <div className="menu-card-img">
                                            <img src={item?.MenuImages[0]?.image ? `${image_url}${MENU_IMAGE_PATH}` + item?.MenuImages[0]?.image : ''} alt='not found' />
                                        </div>
                                        <div className="menu-desc">
                                            <div className="menu-desc-head">
                                                <h5>{item.title.replace(/<[^>]+>/g, '')}</h5>
                                            </div>
                                            <p dangerouslySetInnerHTML={{ __html: item.description.replace(/<[^>]+>/g, '') }}></p>
                                            {/* <div className="menu-desc-head">
                                                <h5 className="">{item.title}</h5>
                                            </div>
                                            <p>
                                                {item.description}
                                            </p> */}
                                            <NavLink className="btn btn-1" to={`/menuDetails/${item.idMenu}`}>
                                                View more
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </main>

        </React.Fragment>
    )
}

export default MenuByCategory
