import { Outlet } from 'react-router-dom'
import React from 'react'
import Header from './Common/Header/Header'
import { Footer } from './Common/Footer/Footer'
import { useEffect } from 'react'
import { useState } from 'react'
import Loader from './Common/Loader'

export const MainLayout = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    });

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <div id='wrapper'>
                    <div id='content-wrapper' className='d-flex flex-column'>
                        <div id='content'>
                            <Header />
                            <Outlet />
                        </div>
                        <Footer />
                    </div>
                </div>
            )}
        </>
    )
}
