import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { base_url } from '../../Utils/baseUrl';
import { useLocation, useNavigate } from 'react-router-dom';
import './verifyotp.css'


function VerifyOTP() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const location = useLocation();
    const email = location.state;
    const navigate = useNavigate();
    const validationSchema = yup.object({
        otp: yup.string().required('OTP is required').length(6, 'OTP must be 6 characters long'),
    });

    const formik = useFormik({
        initialValues: {
            email: email.email,
            otp: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            try {
                axios.post(`${base_url}auth/verify-otp`, values).then((res) => {
                    toast.success(res.data.message);
                    setTimeout(() => {
                        navigate("/login");
                    }, 2000);
                }).catch((err) => {
                    toast.error(err.response.data.message);
                    setTimeout(() => {
                        navigate("/signup");
                    }, 2000);
                });
            } catch (error) {
                toast.error('Please wait!! Something went wrong !!');
            }
        },
    });

    return (
        <React.Fragment>
            <main>
                <section className="verify-otp-sec wow pulse">
                    <div className="container">
                        <div className="verify-otp-inner">
                            <div className="row">
                                <div className="col-md-12">
                                    <form id="verifyform" className="form_elements" onSubmit={formik.handleSubmit}>
                                        <div id="verifyotptitle" className="form-title">
                                            <h2>Verify OTP</h2>
                                        </div>
                                        <div id="verifyotpformgroup" className="form-group">
                                            <div id="verifyotpwrap" className="label_wrap">
                                                <label htmlFor="otp">OTP</label>
                                                <abbr>*</abbr>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="otp"
                                                name="otp"
                                                placeholder="Please enter the one-time password"
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^[0-9]*$/.test(value)) {
                                                        formik.handleChange(e);
                                                    }
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.otp}
                                                pattern="[0-9]*"
                                            />
                                            {formik.touched.otp && formik.errors.otp ? (
                                                <div className="error-message">{formik.errors.otp}</div>
                                            ) : null}
                                        </div>
                                        <div className="btn_submit">
                                            <button type="submit" className="btn btn-1 w-100">
                                                Verify OTP
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </React.Fragment>
    );
}

export default VerifyOTP;
