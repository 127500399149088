import React, { useEffect, useState } from 'react'
import './Style.css'
import { MENU_IMAGE_PATH, image_url } from '../../Utils/baseUrl';
import OrderModal from './OrderModal';
import { useDispatch, useSelector } from 'react-redux';
import commonFunction from '../../Utils/commonFunction';
import { toast } from 'react-toastify';
import { getWalletAmount } from '../../app/wallet/wallet.slice';
import { useNavigate } from 'react-router-dom';
import WalletModal from './WalletModal';
import { getOrders } from '../../app/orders/order.slice';
import { alertMessage } from '../../Utils/constants';


function OrderList(props) {
    const { hotMeal, setHotMeal, selectedFoodMeal, setSelectedFoodMeal, child, setChild, lastInserted, setLastInserted, setSelectedDates, selectedDates, setLoader } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [counter, setCounter] = useState(0) //Set The value of Counter
    const [openViewDialog, setOpenViewDialog] = useState(false);
    const [foodDetail, setFoodDetail] = useState('');
    const [showmodal, setShowModal] = useState(false);
    const [remainingAmount, setRemainingAmount] = useState('');

    useEffect(() => {
        localStorage.setItem('selectedMeal', JSON.stringify(hotMeal));
        localStorage.setItem('selectedFoodMeal', JSON.stringify(selectedFoodMeal));
        dispatch(getOrders({ hotMeal, selectedFoodMeal }));
        if (!hotMeal) {
            hotMeal = JSON.parse(localStorage.getItem('selectedMeal'));
        }
        if (!selectedFoodMeal) {
            selectedFoodMeal = JSON.parse(localStorage.getItem('selectedFoodMeal'));
        }
    }, [hotMeal, selectedFoodMeal])


    // Get the wallet amount
    const userWalletAmount = useSelector((state) => state.wallet?.amount);
    const totalAmount = useSelector((state) => state.order?.order);

    // Function is called everytime increment button is clicked for the hot meal
    const increment = (id) => {
        hotMeal.map((item, index) => {
            if (item.idMenu === id) {
                // Counter state is incremented
                item.quantity++;
                setCounter(counter + 1);
            }
            return true;
        })
        localStorage.setItem('selectedMeal', JSON.stringify(hotMeal));
        dispatch(getOrders({ hotMeal, selectedFoodMeal }));
    }

    // Function is called everytime decrement button is clicked for the hot meal
    const decrement = (id) => {
        // Counter state is decremented 
        hotMeal.map((item, index) => {
            if (item.idMenu === id) {

                if (item.quantity > 1) {
                    // Counter state is incremented
                    item.quantity--;
                    setCounter(counter - 1);
                }
            }
            return true;
        })
        localStorage.setItem('selectedMeal', JSON.stringify(hotMeal));
        dispatch(getOrders({ hotMeal, selectedFoodMeal }));

    }

    //Increment for Food Meal

    const incrementFood = (id) => {
        selectedFoodMeal.map((item, index) => {
            if (item.id === id) {
                // Counter state is incremented
                item.quantity++;
                setCounter(counter + 1);
            }
            return true;
        })
        localStorage.setItem('selectedFoodMeal', JSON.stringify(selectedFoodMeal));
        dispatch(getOrders({ hotMeal, selectedFoodMeal }));
    }

    //Decrement for food Meal

    const decrementFood = (id) => {
        // Counter state is decremented 
        selectedFoodMeal.map((item, index) => {
            if (item.id === id) {

                if (item.quantity > 1) {
                    // Counter state is incremented
                    item.quantity--;
                    setCounter(counter - 1);
                }
            }
            return true;

        })
        localStorage.setItem('selectedFoodMeal', JSON.stringify(selectedFoodMeal));
        dispatch(getOrders({ hotMeal, selectedFoodMeal }));

    }

    const handelRemoveFood = (id) => {

        // Change the normal type of the date from sting tio date object.
        const date = id?.date instanceof Date ? id.date : new Date(id?.date);
        const dateString = !isNaN(date) ? date.toDateString() : '';

        // const dateString = id?.date?.toDateString();

        // Toggle date selection
        if (selectedDates.includes(dateString)) {
            setSelectedDates(selectedDates.filter(d => d !== dateString));
        } else {
            setSelectedDates([...selectedDates, dateString]);
        }

        setLastInserted('')
        const updatedFoodMeals = selectedFoodMeal.filter(item => item.id !== id.id);
        setSelectedFoodMeal(updatedFoodMeals);
        localStorage.setItem('selectedFoodMeal', JSON.stringify(updatedFoodMeals));
        dispatch(getOrders({ hotMeal, updatedFoodMeals }));

    }

    const handelRemoveHotmeal = (id) => {

        setLastInserted('')
        const updatedHotMeals = hotMeal.filter(item => item.idMenu !== id?.idMenu);
        setHotMeal(updatedHotMeals)
        localStorage.setItem('selectedMeal', JSON.stringify(updatedHotMeals));
        dispatch(getOrders({ hotMeal, updatedHotMeals }));
    }

    const handleMealDetail = (data) => {
        setOpenViewDialog(true);
        setFoodDetail(data);
    }

    function formatDate(dateStr) {
        if (!dateStr) {
            return ''; // Handle case when date is not available
        }
        const date = new Date(dateStr);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Note: Month starts from 0
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    const handleOrder = async (data) => {
        // setLoader(true);
        if (lastInserted && (lastInserted.date === null || !lastInserted.hasOwnProperty('date'))) {
            alert(alertMessage);
            return;
        }
        const { hotMeal, selectedFoodMeal } = data;

        // Check if any item in hotMeal or selectedFoodMeal does not have a date
        const missingDateInHotMeal = hotMeal.some(item => !item.date || item.date === null);
        const missingDateInSelectedFoodMeal = selectedFoodMeal.some(item => !item.date || item.date === null);

        if (missingDateInHotMeal || missingDateInSelectedFoodMeal) {
            alert('One or more meals are missing a delivery date. Please ensure all meals have a delivery date.');
            return;
        }
        let orderItem = [];
        let totalPrice = 0;

        // handle order for the hot meal
        if (hotMeal.length !== 0) {
            hotMeal.map((item) => {
                orderItem.push({
                    childId: child.id,
                    deliveryDate: formatDate(item.date),
                    totalPrice: (parseInt(item.Menu.price) * item.quantity),
                    orderItems: [
                        {
                            menuId: item.Menu.idMenu,
                            itemName: item.Menu.title,
                            description: item.Menu.description,
                            price: item.Menu.price,
                            quantity: item.quantity
                        }
                    ]
                })
                return true;
            })
        }

        // handle food meal order
        if (selectedFoodMeal.length !== 0) {
            selectedFoodMeal.map((item) => {
                orderItem.push({
                    childId: child.id,
                    deliveryDate: formatDate(item.date),
                    totalPrice: (parseInt(item.price) * item.quantity),
                    orderItems: [
                        {
                            menuId: item.idMenu,
                            itemName: item.title,
                            description: item.description,
                            price: item.price,
                            quantity: item.quantity
                        }
                    ]
                })
                return true;
            })
        }
        orderItem.map((item) => {
            totalPrice = totalPrice + (parseInt(item.totalPrice));
            return true;
        });

        if (totalPrice > userWalletAmount.wallet) {
            setShowModal(true);
            setLoader(false);
            setRemainingAmount(parseInt(totalPrice) - parseInt(userWalletAmount.wallet))
            toast.error('insufficiant Wallet Amount');

        } else {
            // orderItem.map((item) => {
            //     const response = commonFunction.addOrder(item);
            //     response.then((res) => {
            //         if (res.status === 200) {
            //             toast.success('order Placed successfully');
            //             dispatch(getWalletAmount());
            //             setTimeout(() => {
            //                 navigate("/myprofile", {
            //                     state: {
            //                         isOrder: true
            //                     }
            //                 });
            //             }, 2000);
            //         }
            //     })
            //     return true;
            // })

            const placeOrdersSequentially = async () => {
                // for (const item of orderItem) {
                //     const response = await commonFunction.addOrder(item);
                //     if (response.status === 200) {
                //         toast.success('Order placed successfully');
                //         dispatch(getWalletAmount());
                //         await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 2 seconds
                //         navigate("/myprofile", {
                //             state: {
                //                 isOrder: true
                //             }
                //         });
                //         localStorage.clear(); // clear all the local storage after order success
                //         // Empty the hotMeal and selectedFoodMeal
                //         setHotMeal([]);
                //         setSelectedFoodMeal([]);
                //         setChild('');
                //         // Dispatch getOrders to refresh the order list
                //         dispatch(getOrders({ hotMeal: [], selectedFoodMeal: [] }));
                //     }
                // }
                let allOrdersPlaced = true;

                for (const item of orderItem) {
                    const response = await commonFunction.addOrder(item);
                    if (response.status === 200) {
                        toast.success('Order placed successfully');
                        dispatch(getWalletAmount());
                        await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 2 seconds
                    } else {
                        allOrdersPlaced = false;
                        break; // Exit the loop if an order fails
                    }
                }

                if (allOrdersPlaced) {
                    setLoader(false);

                    navigate("/myprofile", {
                        state: {
                            isOrder: true
                        }
                    });
                    localStorage.clear(); // clear all the local storage after order success
                    // Empty the hotMeal and selectedFoodMeal
                    setHotMeal([]);
                    setSelectedFoodMeal([]);
                    setChild('');
                    // Dispatch getOrders to refresh the order list
                    dispatch(getOrders({ hotMeal: [], selectedFoodMeal: [] }));
                } else {
                    toast.error('Failed to place one or more orders');
                }
            };
            placeOrdersSequentially();
        }
    }

    const handleInsertMoney = () => {
        setShowModal(true);
    }

    return (
        <>
            <div className="conatiner">
                <div className="row">
                    <div className="col-md-5"></div>
                    <div className="col-md-7">
                        {
                            hotMeal && hotMeal.length !== 0 && (
                                hotMeal.map((item, index) => {
                                    return (
                                        <ul key={index} className='p-2 list-group-item-dark d-flex justify-content-between order-list'>
                                            {/* Image */}
                                            <li className='p-2'>
                                                {
                                                    item.Menu.MenuImages !== undefined && item.Menu.MenuImages.length ? (
                                                        <div className="bordered-img-table">
                                                            <img src={`${image_url}${MENU_IMAGE_PATH}` + item?.Menu.MenuImages[0]?.image} className="table-img" alt="Menu" />
                                                        </div>
                                                    ) : "--"
                                                }
                                            </li>
                                            <li className='p-2'>
                                                {item.Menu.title} {parseFloat((item.Menu.price) * parseInt(item.quantity))}.00 Kr
                                            </li>
                                            <li>
                                                <button onClick={() => { decrement(item.idMenu) }} className='btn btn-danger btn-sm'>-</button>
                                                <input type="text" name='quantity' value={item.quantity} min={0} readOnly />
                                                <button onClick={() => { increment(item.idMenu) }} className='btn btn-success btn-sm'>+</button>
                                            </li>
                                            <li className='p-2'>
                                                {item.hasOwnProperty('date') ? new Intl.DateTimeFormat(['ban', 'id']).format(new Date(item.date)) : null}
                                            </li>
                                            <li>
                                                <button className='btn btn-warning' onClick={() => { handleMealDetail(item) }} >Details</button>
                                            </li>
                                            <li>
                                                <button className='btn btn-warning' onClick={() => { handelRemoveHotmeal(item) }}>Delete</button>
                                            </li>
                                        </ul>
                                    )
                                })
                            )
                        }
                        {
                            selectedFoodMeal && selectedFoodMeal.length !== 0 && (
                                selectedFoodMeal.map((item, index) => {
                                    return (
                                        <ul key={index} className='p-2 list-group-item-dark d-flex justify-content-between order-list'>
                                            {/* Image */}
                                            <li className='p-2'>
                                                {
                                                    item.MenuImages !== undefined && item.MenuImages.length ? (
                                                        <div className="bordered-img-table">
                                                            <img src={`${image_url}${MENU_IMAGE_PATH}` + item?.MenuImages[0]?.image} className="table-img" alt="Menu" />
                                                        </div>
                                                    ) : "--"
                                                }
                                            </li>
                                            <li className='p-2'>
                                                {item.title} {parseFloat((item.price) * parseInt(item.quantity))}.00 Kr
                                            </li>
                                            <li>
                                                <button onClick={() => { decrementFood(item.id) }} className='btn btn-danger btn-sm'>-</button>
                                                <input type="text" name='quantity' value={item.quantity} min={0} readOnly />
                                                <button onClick={() => { incrementFood(item.id) }} className='btn btn-success btn-sm'>+</button>
                                            </li>
                                            <li className='p-2'>
                                                {item.hasOwnProperty('date') ? new Intl.DateTimeFormat(['ban', 'id']).format(new Date(item.date)) : null}

                                            </li>
                                            <li>
                                                <button className='btn btn-warning' onClick={() => { handleMealDetail(item) }}>Details</button>
                                            </li>
                                            <li>
                                                <button className='btn btn-warning' onClick={() => { handelRemoveFood(item) }}>Delete</button>
                                            </li>
                                        </ul>
                                    )
                                })
                            )
                        }
                        {
                            (hotMeal.length !== 0 || selectedFoodMeal.length !== 0) && (
                                <div className="d-grid gap-2 justify-content-md-end p-2">
                                    {
                                        userWalletAmount.wallet < totalAmount.totalPrice && (
                                            <>
                                                <span style={{ color: 'red', fontWeight: '400' }}>You don't have enough money</span>
                                                <button className="btn btn-1" type="button" onClick={() => { handleInsertMoney() }}>Insert Amount</button>
                                            </>
                                        )
                                    }
                                    {
                                        userWalletAmount.wallet >= totalAmount.totalPrice && (
                                            <>
                                                <button className="btn btn-1" type="button" onClick={() => { handleOrder({ hotMeal, selectedFoodMeal }) }}>Place Order</button>
                                            </>
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            {/* View order modal dialog */}
            <OrderModal
                open={openViewDialog}
                onClose={() => setOpenViewDialog(false)}
                detail={foodDetail}
            />

            <WalletModal handleOpen={showmodal} handleClose={() => setShowModal(false)} remainingAmount={remainingAmount} />

        </>
    )
}

export default OrderList