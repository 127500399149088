import React from 'react'

function Terms() {
    return (
        <>
            <main>
                <section className="page-info-sec">
                    <div
                        className="bg-fixed wow fadeIn"
                        data-wow-delay="0.1s"
                        style={{ background: 'url("assets/img/page-info1.png")' }}
                    />
                    <div className="bg-fixed img-overlay wow fadeIn" data-wow-delay="0.2s" />
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-md-12">
                                <div className="page-info-block wow fadeInUp" data-wow-delay="0.3s">
                                    <h1>Terms & Conditions</h1>
                                    <div className="btm-line-2 text-center justify-content-center">
                                        <span />
                                        <span />
                                    </div>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulmtas
                                        pulvinar massa, ac volutpat augue finibus in. Pellentesque auctor
                                        eget massa nec ornare.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sec-pd rel-z-1">
                    <div className="top-bottom-shaps">
                        <span className="shap shap-1" />
                        <span className="shap shap-2" />
                    </div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 mmb-20">
                                <div className="img-style-1 wow fadeInLeft">
                                    <img src="./assets/img/about-1.png" alt="Not found" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <div
                                        className="title-1 wow fadeInRight"
                                        data-wow-delay="0.1s"
                                        style={{
                                            visibility: "visible",
                                            animationDelay: "0.1s",
                                            animationName: "fadeInUp"
                                        }}
                                    >
                                        <h1>Login - registration as a user</h1>
                                        <div className="btm-line-1">
                                            <span />
                                            <span />
                                        </div>
                                        <p>
                                            To buy food, you must first register as a user.
                                            Once you have registered, you will receive an e-mail with your food name and password.
                                            You must then use this username and password every time you order food.
                                            Once you have logged in, you must deposit an amount into your account with which you can buy food.
                                            You can always delete your profile and get paid, if any.
                                            the surplus on your account for a fee of DKK 50
                                            <br />
                                            It is important that you have always updated your account information.
                                        </p>
                                        <div className="mt-3">
                                            <h2>Payment</h2>
                                            <div className="btm-line-1">
                                                <span />
                                                <span />
                                            </div>
                                            <p>
                                                To buy food, you must deposit money into your account via your Dankort.
                                                You can only buy food for the amount you have in your account. You can deposit more money into your account at any time.
                                                There will be a small fee per payment to pbs which will appear when you approve your payment.
                                                We use quickpay.dk as a payment gateway for Dankort, read more on their page about the security of online payment.
                                                <br /><br />
                                                You may deposit a maximum of DKK 999
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sec-pd bg-gray position-relative">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 mmb-20">
                                <div>
                                    <div className="title-1 wow fadeInUp" data-wow-delay="0.1s">
                                        <h1>Ordering</h1>
                                        <div className="btm-line-1">
                                            <span />
                                            <span />
                                        </div>
                                        <p>
                                            We are open 24 hours a day, so you can always log in and order food. When you order your food, you must log in and then select the day you want to order food for. Once you have chosen the day, you can freely choose between all our good products. When you have finished choosing food for the day you have chosen

                                            you just click on another day in the calendar to order for another day. You can order up to 3 months ahead.
                                        </p>
                                        <h1>Cancellation</h1>
                                        <div className="btm-line-1">
                                            <span />
                                            <span />
                                        </div>
                                        <p>
                                            You can cancel or change your order, up to 06.00 on the same day as the order is to be delivered.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="img-style-3 pl-2per img-res-md wow fadeInRight">
                                    <img src="./assets/img/download-mobile.png" alt="Not found" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="download-banner img-res-md">
                        <img src="./assets/img/download-app.png" alt="Not found" />
                    </div>
                </section>
                <section className="sec-pd rel-z-1">
                    <div className="top-bottom-shaps">
                        <span className="shap shap-1" />
                        <span className="shap shap-2" />
                    </div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 mmb-20">
                                <div>
                                    <div className="title-1 wow fadeInUp" data-wow-delay="0.1s">
                                        <h1>Collection</h1>
                                        <div className="btm-line-1">
                                            <span />
                                            <span />
                                        </div>
                                        <p>
                                            All food packages are marked with a sticker that clearly shows who the lunch package belongs to. It will appear: name and grade level on the label, so that there can be no doubt as to who should have the food. If the student is ill or in some other way prevented from picking up his packed lunch, he will not be refunded his purchase.
                                        </p>
                                        <h1>Right of cancellation - Complaints</h1>
                                        <div className="btm-line-1">
                                            <span />
                                            <span />
                                        </div>
                                        <p>
                                            Since you have the option to change or delete your order up to midnight, you do not have the option to cancel your purchase until after 06.00 on the day when the food has been produced. If you have any complaints about the food, please write to us via our contact form here.

                                            Prices
                                            All prices are incl. VAT. We reserve the right for price changes and printing errors.

                                            Inactive Account

                                            If your account has not been used for 3 years, we have the right to delete it.

                                            If there is good money, it will be sent back to the last used card
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="pl-10per wow fadeInRight">
                                    <img src="./assets/img/childrens-3.png" className="img-fluid" alt="Not found" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Terms