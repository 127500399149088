import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../Common/Loader";
import Slider from "../Common/Slider/Slider";
import DishOfDay from "../Menu/DishOfDay";
import Menu from "../Menu/Menu";

function Home() {
  const [loader, setLoader] = useState(true);

  const user = useSelector((state) => state.user);
  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, [loader]);

  if (loader) {
    return <Loader />;
  }

  return (
    <main>
      <section className="banner-sec">
        <div className="banner-slider ">
          <div className="banner-block">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="banner-inner-block wow fadeInUp">
                    {/* <p className="title-top">balanced meal plan for kids</p> */}
                    <p className="title-top">Balancered Madplan før børn</p>
                    <h3 className="banner-title">
                      {" "}
                      <span className="clr1">Friske måltider </span> for de små
                    </h3>
                    <div className="btm-line">
                      <span />
                      <span />
                    </div>
                    <p className="title-text-for">
                      Vi har Chauffør der levere maden hverdag. Vores
                      kunder/skoleelever er rigtig gode til at komme med nye
                      forslag til retter de kunne tænke sig. Det er rigtig godt
                      med den dialog, det giver os gensidig tilfredshed.{" "}
                    </p>
                    <Link
                      className="btn btn-1"
                      to={user?.user?.status ? "/menu" : "/signup"}
                    >
                      Get Started
                    </Link>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="banner-img wow fadeInRight">
                    <svg
                      width={288}
                      height={281}
                      viewBox="0 0 288 281"
                      fill="none"
                      className="left-shap"
                    >
                      <path d="M0.120605 200.066C0.120605 89.609 89.6637 0.065918 200.121 0.065918H257.956C274.525 0.065918 287.956 13.4974 287.956 30.0659V250.692C287.956 267.26 274.525 280.692 257.956 280.692H30.1206C13.552 280.692 0.120605 267.26 0.120605 250.692V200.066Z" />
                    </svg>
                    <img src="./assets/img/childrens-2.png" alt="" />
                    <svg
                      width={289}
                      height={281}
                      viewBox="0 0 289 281"
                      fill="none"
                      className="right-shap"
                    >
                      <path
                        d="M288.341 200.272C288.341 89.8146 198.798 0.271484 88.3413 0.271484H30.5058C13.9372 0.271484 0.505798 13.7029 0.505798 30.2715V250.897C0.505798 267.466 13.9373 280.897 30.5058 280.897H258.341C274.91 280.897 288.341 267.466 288.341 250.897V200.272Z"
                        fill="#FFB119"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec-pd rel-z-1">
        <div className="top-bottom-shaps">
          <span className="shap shap-1" />
          <span className="shap shap-2" />
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 mmb-20">
              <div className="img-style-1 wow fadeInLeft">
                <img src="./assets/img/childrens-1.png" alt="not found" />
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <div className="title-1 wow fadeInUp" data-wow-delay="0.1s">
                  <h1>Gør Frokosten Lettere</h1>
                  <div className="btm-line-1">
                    <span />
                    <span />
                  </div>
                  <p>
                    Med Samarbejde med skolerne, har vi sammen lavet en system
                    til at levere maden i frikvarteret, hvor vi levere dem i
                    bokse hvor der er varme retter så de holdes varm, og i
                    bakker med kolde retter, vi har en medarbejder der står klar
                    til at hjælpe, skulle der ske problemer med bestillingen
                    eller andet.{" "}
                  </p>
                  {/* <p>
                    malesuada mauris. Sed gravida arcu et velit placerat
                    volutpat. Curabitur non efficitur metus. iquam erat
                    volutpat. Quisque placerat, metus convallis fringilla
                    vehicula, turpis turpis rutrum massa, quis feugiat justo
                    metus a dolor. Aliquam ullamcorper mollis tortor. Ut finibus
                    mentum eros, non blandit dui. Nulla dapibus quam tellus,
                    vestibulum pulvinar nulla vehicula in. Pellentesque magna
                    ex, eleifend sagittis lacus id, fermentum ornare diam.
                    Aenean tempor scelerisque blandit. Cras rhoncus metus ut
                    justo{" "}
                  </p> */}
                </div>
                <Link className="btn btn-1" to={"/about"}>
                  Læs Mere
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Menu section */}
      <Menu />
      {/* Dish of the day Section */}
      <DishOfDay />
      <section className="sec-pd rel-z-1">
        <div className="top-bottom-shaps">
          <span className="shap shap-1" />
          <span className="shap shap-2" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 mmb-20">
              <div className="title-1 wow fadeInUp" data-wow-delay="0.2s">
                <h1>Hvordan Virker Det</h1>
                <div className="btm-line-1">
                  <span />
                  <span />
                </div>
              </div>
              <div className="how-it-works-sec">
                <div className="hiw-box wow fadeInLeft" data-wow-delay="0.2s">
                  <span className="hiw-num">01</span>
                  <h2>Vælg Din Frokost</h2>
                  <p>
                    Ved at vælge hvilken barn/børn der skal have sin mad Kan du
                    vælge en række forskellige retter, om det Vegetar, Vegan,
                    Halal, Varme Retter, Kolde Retter eller andet. Vi har en
                    masse at vælge imellem.{" "}
                  </p>
                </div>
                <div className="hiw-box wow fadeInLeft" data-wow-delay="0.3s">
                  <span className="hiw-num">02</span>
                  <h2>Betal For din ordre</h2>
                  <p>
                    Når du har valgt maden, vælger du den dato det skal leveres.
                    Du kan vælge om det skal være den her uge eller næste, du
                    kan sagtens købe fra flere uger af gangen. De varme retter
                    er dog faste dage, hvor man kan bestille.{" "}
                  </p>
                </div>
                <div className="hiw-box wow fadeInLeft" data-wow-delay="0.4s">
                  <span className="hiw-num">03</span>
                  <h2>Ordre Gennemført</h2>
                  <p>
                    Så klikker du bare betal, og du bliver viderestillet til
                    ordre siden så du kan se den ordre du har bestilt, og tjekke
                    om alt er som det skal være, hvis der er en fejl så bare
                    rolig du kan stadig slette ordre og genbestille, indtil kl 6
                    om morgen samme dag, Maden bliver leveret til givet dato på
                    skolen, hvor barn/børnene kan hente deres mad.{" "}
                  </p>
                </div>
              </div>
              <div className="flex-group play-app">
                <Link className="btn btn-dark">
                  <span className="play_img">
                    <img src="assets/img/palystore-icon.svg" alt="not found" />
                  </span>
                  <div className="download-cont">
                    <span>Download on the</span>
                    <p>App Store</p>
                  </div>
                </Link>
                <Link className="btn btn-dark google_ply">
                  <span className="play_img">
                    <img src="assets/img/apple-icon.svg" alt="not found" />
                  </span>
                  <div className="download-cont">
                    <span>Get it on</span>
                    <p>Google Play</p>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-md-6">
              <div className="img-style-2 wow fadeInRight">
                <img src="./assets/img/phone-img.png" alt="not found" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Slider /> client says to comment this line */}
    </main>
  );
}

export default Home;
