import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TransactionService from "./Transaction.services";

export const getTransaction = createAsyncThunk(
    "transaction/get-transaction",
    async (data, thunkAPI) => {
        try {
            return await TransactionService.getTransaction(data);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const getSuccessTransaction = createAsyncThunk(
    "transaction/transaction-success",
    async (data, thunkAPI) => {
        try {
            return await TransactionService.getSuccessTransaction(data);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const getCancelTransaction = createAsyncThunk(
    "transaction/transaction-cancel",
    async (data, thunkAPI) => {
        try {
            return await TransactionService.getCancelTransaction(data);
        } catch (error) {
            return await error.response.data;
        }
    }
);

export const resetState = createAction("RevertAll");

const initialState = {
    transaction: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
};

export const TransactionSlice = createSlice({
    name: "transaction",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTransaction.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getTransaction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.transaction = action.payload.data;
                state.message = action.payload.message
            })
            .addCase(getTransaction.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(getSuccessTransaction.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getSuccessTransaction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.transactionSuccess = action.payload.data;
                state.message = action.payload.message
            })
            .addCase(getSuccessTransaction.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(getCancelTransaction.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getCancelTransaction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.transactionCancel = action.payload.data;
                state.message = action.payload.message
            })
            .addCase(getCancelTransaction.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(resetState, () => initialState);
    },
});

export default TransactionSlice.reducer;
