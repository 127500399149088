import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userLogout } from "../../app/login/login.slice";
import Cookies from "js-cookie";
import Loader from "../Common/Loader";
import { config } from "../../Utils/axiosconfig";

const ProtectedRoute = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  let logoutTimer;

  const handleLogout = async () => {
    setLoader(true);
    Cookies.remove("token");
    localStorage.clear(); // clear all the local storage
    const res = await dispatch(userLogout(config));
    if (res?.payload?.status === true) {
      await navigate("/login");
      setLoader(false);
    }
  };

  const resetTimer = () => {
    clearTimeout(logoutTimer);
    logoutTimer = setTimeout(() => {
      handleLogout();
    }, 900000);
  };

  useEffect(() => {
    if (user && user.user && user.user.status) {
      window.addEventListener("mousemove", resetTimer);
      window.addEventListener("keypress", resetTimer);

      resetTimer();

      return () => {
        clearTimeout(logoutTimer);
        window.removeEventListener("mousemove", resetTimer);
        window.removeEventListener("keypress", resetTimer);
      };
    }
  }, [user, dispatch]);
  if (loader) {
    return <Loader />;
  }

  if (!user || user.user.status === false) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
