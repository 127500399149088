import React from 'react';
import './errorpage.css';


function ErrorPage() {

    return (
        <div>
            <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet" />
            <div className="page-404">
                <div className="outer">
                    <div className="middle">
                        <div className="inner">
                            <div className="inner-circle"><i className="fa fa-cutlery" /><span>404</span></div>
                            <span className="inner-status">Oops! You're lost</span>
                            <span className="inner-detail">
                                We can not find the page you're looking for
                            </span>
                            <span className='inner-detail'>
                                <a href="/" className="btn btn-info mtl home_button"><i className="fa fa-home" />&nbsp;
                                    Return home
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ErrorPage