import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CategoryService from './categoriesServices';

export const fetchCategories = createAsyncThunk('categories/fetchCategories', async (categoryData, thunkAPI) => {
    try {
        return await CategoryService.getCategories(categoryData);

    } catch (error) {
        return await error.response.data;
    }
});
const categoriesSlice = createSlice({
    name: 'categories',
    initialState: {
        loading: false,
        categories: [],
        error: ''
    },

    // reducer will call here
    extraReducers: builder => {
        builder.addCase(fetchCategories.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchCategories.fulfilled, (state, action) => {
            state.loading = false;
            state.categories = action.payload;
            state.error = '';
        });
        builder.addCase(fetchCategories.rejected, (state, action) => {
            state.loading = false;
            state.categories = [];
            state.error = action.error.message;
        });
    }
});
export default categoriesSlice.reducer; 