import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { base_url } from "../../Utils/baseUrl";

export const fetchMenuDetails = createAsyncThunk('menus/menus-detail', async (id) => {
    return axios.get(`${base_url}menus/${id}`)
    .then(res => res.data)
});

const menuDetailSlice = createSlice({
    name: 'menuDetails',
    initialState: {
        loading: false,
        menuDetails: [],
        error: ''
    },

    // reducer will call here
    extraReducers: builder => {
        builder.addCase(fetchMenuDetails.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchMenuDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.menuDetails = action.payload;
            state.error = '';
        });
        builder.addCase(fetchMenuDetails.rejected, (state, action) => {
            state.loading = false;
            state.menuDetails = [];
            state.error = action.error.message;
        });
    }
});
export default menuDetailSlice.reducer;