import React, { useState, useEffect } from 'react'
import { base_url } from "../../../Utils/baseUrl";
import axios from 'axios'; // Import Axios
import { Link } from 'react-router-dom';

export const Accordion = () => {
    // Set State for FAQ Data
    const [faqData, setFaqData] = useState([]);
    const [activeTab, setActiveTab] = useState();
    const pageNumber = 1;
    const pageSize = 4;

    const sanitizeHTML = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    useEffect(() => {
        // Fetch data from your FAQ endpoint using Axios
        axios.get(`${base_url}faqs/active?pageNumber=${pageNumber}&pageSize=${pageSize}`)
            .then((response) => {
                let res = response.data.data;
                setFaqData(res);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    // Toggle Accordion Function
    const toggleAccordion = (i) => {
        activeTab === i ? setActiveTab(null) : setActiveTab(i);
    };

    return (
        <>
            {/* Accordion start */}
            {
                faqData.map((item, index) => {
                    return (
                        <div key={index} className="accordion wow fadeIn" data-wow-delay="0.4s">
                            <div className={activeTab === index ? 'accordion-item exp' : 'accordion-item'}>
                                <div className="accordion-titel accordion-header" onClick={() => toggleAccordion(index)}>
                                    <h2>Q: {sanitizeHTML(item.question.substring(0, 500))} <span><i className="fa fa-angle-down" /></span></h2>
                                </div>
                                <div>
                                    {activeTab === index && (
                                        <div className="accordion-content">
                                            {/* Content goes here */}
                                            <p>
                                                {sanitizeHTML(item.answer)}
                                            </p>
                                            {
                                                item.referenceLink && (
                                                    <p>
                                                        <b>Reference link:</b> <Link target='_blank' to={item.referenceLink}>{item.referenceLink}</Link>
                                                    </p>
                                                )
                                            }
                                            {
                                                item.videoLink && (
                                                    <p>
                                                        <b>Video Link:</b><br />
                                                        <iframe title="video" width="560" height="315" src={item.videoLink.replace("watch?v=", "embed/")} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                    </p>
                                                )
                                            }
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            {/* Accordion Ends */}
        </>
    )
}
