import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { base_url } from '../../Utils/baseUrl';
import { NavLink, useNavigate } from 'react-router-dom';
import './signup.css';

function SignUp() {

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [success, setSuccess] = useState(false);
  const [isChecked, setIsChecked] = useState();
  const navigate = useNavigate();

  const validationSchema = yup.object({
    // firstName: yup.string().required('firstname is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password is too short - should be 8 chars minimum'),
    terms: yup.boolean().oneOf([true], 'Please accept the terms and conditions'),
  });

  const formik = useFormik({
    initialValues: {
      // firstName: '',
      email: '',
      password: '',
      terms: false,
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      try {
        setLoading(true);
        // if (!values.recaptcha) {
        //   toast.error('Please complete the CAPTCHA');
        //   return;
        // }
        delete values.terms;
        axios.post(`${base_url}auth/register`, values).then((res) => {
          toast.success(res.data.message);
          setLoading(false);
          setSuccess(true);
        }).catch((err) => {
          toast.error(err.response.data.message);
          setLoading(false);
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        });
      } catch (error) {
        toast.error('Please wait!! Something went wrong !!');
      }
    },
  });

  if (success) {
    setTimeout(() => {
      navigate("/verifyOtp", {
        state: {
          email: formik.values.email
        }
      });
    }, 1000);
  }

  return (
    <React.Fragment>
      <main>
        <section className="login-sec signup-sec wow pulse">
          <div className="container">
            <div className="login-inner">
              <div className="row">
                <div className="col-md-6">
                  <form className="form_elements" onSubmit={formik.handleSubmit}>
                    <div className="form-title">
                      <h2> Signup</h2>
                    </div>
                    {/* <div className="form-group ">
                      <div className="label_wrap">
                        <label htmlFor="firstName">Username</label>
                        <abbr>*</abbr>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        name="firstName"
                        placeholder="firstName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName}
                      />
                      {formik.touched.firstName && formik.errors.firstName ? (
                        <div className="error-message">{formik.errors.firstName}</div>
                      ) : null}
                    </div> */}
                    <div className="form-group ">
                      <div className="label_wrap">
                        <label htmlFor="email">Email ID</label>
                        <abbr>*</abbr>
                      </div>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="error-message">{formik.errors.email}</div>
                      ) : null}
                    </div>
                    <div className="form-group ">
                      <div className="label_wrap">
                        <label htmlFor="password">Password</label>
                        <abbr>*</abbr>
                      </div>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                        placeholder="Password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                      />
                      {formik.touched.password && formik.errors.password ? (
                        <div className="error-message">{formik.errors.password}</div>
                      ) : null}
                    </div>
                    {/* reCAPTCHA component */}
                    {/* <div className="form-group">
                    <ReCAPTCHA
                      sitekey="Your reCAPTCHA Site Key"
                      onChange={(value) => formik.setFieldValue("recaptcha", value)}
                    />
                  </div> */}
                    <div className="form-group justify-content-between">
                      <div className="form_check">
                        <label className="form-check-label1" htmlFor="gridCheck1">
                          Already have an account?{' '}
                          <NavLink className="text-decoration-none" to="/login" >
                            Login
                          </NavLink>
                        </label>
                      </div>
                      <br />

                      <div className="form_check">
                        <input
                          style={{ marginRight: '10px' }}
                          className="form-check-input"
                          type="checkbox"
                          id="gridCheck"
                          checked={formik.values.terms}
                          onChange={() => {
                            formik.setFieldValue("terms", !formik.values.terms);
                            setIsChecked(!isChecked);
                          }}
                        />
                        <label className="form-check-label" htmlFor="gridCheck">
                          I Accept&nbsp;
                          <NavLink className="text-decoration-none" to="/terms" style={{ color: '#1cc88a!important' }} >
                            <u>Terms</u> & <u> Conditions{' '}</u>
                            <abbr style={{ color: 'red' }} >*</abbr>
                          </NavLink>
                          {formik.touched.terms && formik.errors.terms ? (
                            <div className="error-message">{formik.errors.terms}</div>
                          ) : null}

                        </label>
                      </div>
                    </div>
                    <div className="btn_submit">
                      {loading === false
                        ? <button type="submit" className="btn btn-1 w-100">Signup</button>
                        : <button type="button" className="btn btn-1 w-100">Loading ....</button>
                      }
                    </div>
                  </form>
                </div>
                <div className="col-md-6">
                  <div className="login-img">
                    {/* Your other content goes here */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
}

export default SignUp;
