import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import commonFunction from "../../Utils/commonFunction";

function Forgotpassword() {
    const navigate = useNavigate();
    const validationSchema = yup.object({
        email: yup.string().email("Invalid email").required("Email is required"),
    });

    const formik = useFormik({
        initialValues: {
            email: "",
        },

        onSubmit: async (values) => {
            try {
                const response = await commonFunction.sendOtp(values);
                if (response.status === 200) {
                    toast.success(response.data.message, { autoClose: 7000 });
                    formik.resetForm();
                    navigate("/reset-password", {
                        state: {
                            email: values.email,
                        },
                    });
                }
            } catch (error) {
                toast.error("Please wait!! Something went wrong !!");
            }
        },
        validationSchema,
    });

    return (
        <>
            <main>
                <section className="login-sec wow pulse">
                    <div className="container">
                        <div className="login-inner">
                            <div className="row">
                                <div className="col-md-6">
                                    <form
                                        className="form_elements"
                                        onSubmit={formik.handleSubmit}
                                    >
                                        <div className="form-title">
                                            <h2> Forgot Password</h2>
                                            <span>Add Below Your Email, Then we will send you a one time password,
                                                this one time password, will be needed for next step.
                                                If its not in inbox, please check spam & unwanted post, and move it to inbox
                                            </span>
                                        </div>
                                        <div className="form-group">
                                            <div className="label_wrap" style={{ textAlign: 'center' }}>
                                                <label htmlFor="email">Email ID</label>
                                                <abbr>*</abbr>
                                            </div>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                placeholder="Enter Email to Get One Time Password"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.email}
                                                style={{ textAlign: 'center' }}
                                            />
                                            {formik.touched.email && formik.errors.email ? (
                                                <div className="error-message">
                                                    {formik.errors.email}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="btn_submit">
                                            <button
                                                type="submit"
                                                className="btn btn-1 w-100"
                                                disabled={formik.isSubmitting}
                                                style={{ fontSize: '16px' }}
                                            >
                                                Click to get your one time password sent to email
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-6">
                                    <div className="reset-img">
                                        {/* Your other content goes here */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default Forgotpassword;
