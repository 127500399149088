import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { DialogContentText, Grid, Typography } from "@mui/material";
import { MENU_IMAGE_PATH, image_url } from "../../Utils/baseUrl";

function OrderModal({ open, onClose, detail }) {
    return (
        <>
            <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={"md"}>
                <DialogContent>
                    {detail?.Menu ? (
                        <>
                            {/* for Hot meals */}
                            <Grid
                                item={true}
                                xs={10}
                                style={{ display: "flex", gap: "1rem" }}
                            >
                                <Grid style={{ width: "50%" }}>
                                    <img
                                        src={
                                            `${image_url}${MENU_IMAGE_PATH}` +
                                            detail.Menu?.MenuImages[0]?.image
                                        }
                                        className="d-block w-100"
                                        alt="anurag"
                                    ></img>
                                </Grid>
                                <Grid item={true} xs={10} style={{ width: "50%" }}>
                                    <DialogTitle>{detail?.Menu?.title}</DialogTitle>
                                    <DialogContentText>{detail?.Menu?.price}</DialogContentText>
                                    <hr />

                                    {/* For description */}
                                    <DialogContentText
                                        dangerouslySetInnerHTML={{
                                            __html: detail?.Menu?.description,
                                        }}
                                    >
                                        {/* {detail?.Menu?.description} */}
                                    </DialogContentText>
                                    <hr />
                                    {/* For ingredients */}
                                    <Typography fontWeight="bold">Full Nutritions :-</Typography>
                                    <Typography>
                                        <ul>
                                            {detail?.Menu?.nutrition &&
                                                typeof detail?.Menu?.nutrition === "object" ? (
                                                Object.keys(detail?.Menu?.nutrition).map(
                                                    (key, index) => (
                                                        <li key={index}>
                                                            {key}: {detail?.Menu.nutrition[key]}
                                                        </li>
                                                    )
                                                )
                                            ) : (
                                                <p>No data found</p>
                                            )}
                                        </ul>
                                    </Typography>
                                    <hr />
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <>
                            {/* For menu details */}
                            <Grid
                                item={true}
                                xs={10}
                                style={{ display: "flex", gap: "1rem" }}
                            >
                                <Grid style={{ width: "50%" }}>
                                    {detail.MenuImages !== undefined &&
                                        detail.MenuImages.length ? (
                                        <img
                                            src={
                                                `${image_url}${MENU_IMAGE_PATH}` +
                                                detail?.MenuImages[0]?.image
                                            }
                                            className="d-block w-100"
                                            alt="anurag"
                                        ></img>
                                    ) : (
                                        "--"
                                    )}
                                </Grid>
                                <Grid item={true} xs={10} style={{ width: "50%" }}>
                                    <DialogTitle>{detail?.title}</DialogTitle>
                                    <DialogContentText>{detail?.price}</DialogContentText>
                                    <hr />

                                    {/* For description */}
                                    <DialogContentText dangerouslySetInnerHTML={{
                                        __html: detail?.description,
                                    }}
                                    ></DialogContentText>
                                    <hr />
                                    {/* For nutrition */}
                                    <Typography fontWeight="bold">Full Nutritions :-</Typography>
                                    <Typography>
                                        <ul>
                                            {detail?.nutrition &&
                                                typeof detail?.nutrition === "object" ? (
                                                Object.keys(detail?.nutrition).map((key, index) => (
                                                    <li key={index}>
                                                        {key}: {detail?.nutrition[key]}
                                                    </li>
                                                ))
                                            ) : (
                                                <p>No data found</p>
                                            )}
                                        </ul>
                                    </Typography>

                                    {/* For ingredients */}
                                    <Typography fontWeight="bold">Full Ingredients :-</Typography>
                                    <Typography>
                                        <ul>
                                            {detail?.Ingredients &&
                                                typeof detail?.Ingredients === "object" ? (
                                                Object.values(detail?.Ingredients).map(
                                                    (item, index) => <li key={index}>{item.title}</li>
                                                )
                                            ) : (
                                                <p>No data found</p>
                                            )}
                                        </ul>
                                    </Typography>
                                    <hr />
                                </Grid>
                            </Grid>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <button className="btn btn-secondary" onClick={onClose}>
                        Close
                    </button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default OrderModal;
